import { Application } from '@feathersjs/feathers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStaffRole } from '../Interfaces/Database/StaffRole';
import { IUser } from '../Interfaces/Database/User';

export interface IRootState {
  client: Application | null,
  socket: SocketIOClient.Socket | null,
  square: any,
  authToken: string | null,
  user: IUser | null,
  roles: IStaffRole[],
}

interface IFeathers {
  client: Application | null,
  socket: SocketIOClient.Socket | null,
}

interface IUserLogin {
  authToken: string | null,
  user: IUser | null
}

const initialState: IRootState = {
  client: null,
  socket: null,
  square: null,
  authToken: null,
  user: null,
  roles: []
};
  

export const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setFeathers: (state, action: PayloadAction<IFeathers>) => {
      state.client = action.payload.client;
      state.socket = action.payload.socket;
    },
    setSquare: (state, action: PayloadAction<any>) => {
      state.square = action.payload;
    },
    setUser: (state, action: PayloadAction<IUserLogin>) => {
      state.user = action.payload.user;
      state.authToken = action.payload.authToken;
    },
    setRoles: (state, action: PayloadAction<IStaffRole[]>) => {
      state.roles = action.payload;
    },
  }
});

export const { setFeathers, setUser, setSquare } = rootSlice.actions;
export default rootSlice.reducer;
