import { Container, Typography } from '@mui/material';
import React from 'react';
import logoImage from '../../static-assets/images/logo-transparent.png';

import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { useNavigate } from 'react-router-dom';


export default function Home() {
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.root.user);

  React.useEffect(() => {
    if(!user){
      navigate('/login');
    }
  }, [ user ])

  return (
    <div>
      <Container component='main' maxWidth='xs'>
        <img src={logoImage} style={{ maxHeight: '175px', maxWidth: '90%', margin: '20px' }}/>
        <Typography variant='h5' style={{ fontStyle: 'italic', color: '#14324a', textAlign: 'center' }}>{user?.firstName}, Welcome!</Typography>
      </Container>
      <Container style={{ marginTop: '20px' }}>
        <Typography style={{ color: '#14324a' }}>Thank you for helping to support our auctions.</Typography>
        <Typography style={{ color: '#14324a' }}>On the left navigation, you can view all auctions that you have access to.</Typography>
      </Container>
    </div>
  );
}
