import { Button, CircularProgress, Container, TextField, Typography } from '@mui/material';
import React from 'react';
import logoImage from '../../static-assets/images/logo-transparent.png';
import client from '../../client';
import { addAsterickOnError } from '../../helpers/accessibility';
import { useLocation, useNavigate } from 'react-router-dom';


export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = React.useState<string>('');
  const [password2, setPassword2] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');

  const [isResendEmail, setIsResendEmail] = React.useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [passwordError, setPasswordError] = React.useState<boolean>(false);
  const [password2Error, setPassword2Error] = React.useState<boolean>(false);
  const [successful, setSuccessful] = React.useState<boolean>(false);


  const handleSubmit = () => {
    if(isResendEmail){
      setIsLoading(true);
      client?.service('authManagement').create({
        action: 'sendResetPwd',
        value: {
          email: email + '.staff'
        },
        notifierOptions: {
          staffResetPass: true
        }
      }).then(() => setIsEmailSent(true))
        .catch((err: any) => setEmailErrorMessage('An error has occurred while sending the new token. Try again later.'))
        .finally(() => setIsLoading(false));
    } else if (successful){
      navigate('/login');
    } else {
      const token = location.search.split('?token=').pop();
      setPasswordError(false);
      setPassword2Error(false);
      setErrorMessage('');
      let valid = true;
  
      if(!token) {
        setErrorMessage('Invalid token')
        valid = false;
      }
      if(!password){
        setPasswordError(true);
        setErrorMessage('(*) Field required');
        valid = false;
      }
      if(!password2){
        setPassword2Error(true);
        setErrorMessage('(*) Field required');
        valid = false;
      }
      if(password !== password2){
        setPasswordError(true);
        setPassword2Error(true);
        setErrorMessage('Passwords do not match');
        valid = false;
      }
  
      if(valid){
        setIsLoading(true);
        client?.service('authManagement').create({
          action: 'resetPwdLong',
          value: {
            token,
            password,
          }
        })
          .then((res: any) => {
            setSuccessful(true);
          })
          .catch((err: any) => {
            if ((err.message as string).includes('Token is not in the correct format')) {
              setErrorMessage('Your setup token is not the correct format. Try navigating to the link again. If the issue continues, please input your email address to retrieve a new token. Once email is sent, close this tab and check your email.');
              setIsResendEmail(true);
            } else if ((err.message as string).includes('Password reset token has expired')) {
              setErrorMessage('Your setup token has expired. Please input your email address to retrieve a new token. Once email is sent, close this tab and check your email.');
              setIsResendEmail(true);
            } else {
              setErrorMessage('An error has occurred');
            }
          })
          .finally(() => setIsLoading(false));
      }
    }
  }


  return (
    <Container component='main' maxWidth='xs' >
      <img src={logoImage} style={{ maxHeight: '175px', maxWidth: '90%', margin: '20px' }}/>
      <Typography variant='h5' style={{ fontStyle: 'italic', color: '#14324a', textAlign: 'center' }}>Welcome</Typography>
      {successful
        ?
        <>
           <Typography style={{ color: '#14324a' }}>
            Your password has been successfully reset.
          </Typography>
          <Typography style={{ color: '#14324a', marginTop: '10px' }}>
            Sign in using:
          </Typography>
          <Typography style={{ color: '#14324a', marginTop: '10px' }}>
            - Your email
          </Typography>
          <Typography style={{ color: '#14324a', marginTop: '10px' }}>
            - new password
          </Typography>
        </>
        : <>
            <Typography style={{ color: '#14324a', textAlign: 'center' }}>
              Please create your new password.
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              variant='outlined'
              label={addAsterickOnError(passwordError, 'Password')}
              error={passwordError}
              value={password}
              type='password'
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              variant='outlined'
              label={addAsterickOnError(password2Error, 'Retype Password')}
              error={password2Error}
              value={password2}
              type='password'
              onChange={(e) => setPassword2(e.currentTarget.value)}
            />
            <Typography color={'error'}>{errorMessage}</Typography>
            {isResendEmail &&
            <>
              <TextField
                fullWidth
                margin="normal"
                variant='outlined'
                label={'Email'}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
              <Typography color={'error'}>{emailErrorMessage}</Typography>
            </>
            }
          </>
      }
     
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          sx={{ mt: 3, mb: 2 }}
          disabled={isEmailSent}
        >
          {isLoading
            ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
            : isResendEmail 
              ? isEmailSent ? 'Email Sent!' :'Resend' 
              : successful ? 'Go to Login' : 'Next'
          }
          
        </Button>
      
    </Container>
  );
}
