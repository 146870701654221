import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';

import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import client from '../../client';
import { IStaffRole } from '../../Interfaces/Database/StaffRole';
import { IAuction } from '../../Interfaces/Database/Auction';
import NestedTabsList from './nested-tabs-list';
import { Typography } from '@mui/material';

export interface IAuctionTab {
  roles: IStaffRole[],
  auction: IAuction
}

interface IBucket {
  [auctionID: string]: IStaffRole[]
}

export default function TabsList() {
  const user = useAppSelector((state: RootState) => state.root.user);
  const authToken = useAppSelector((state: RootState) => state.root.authToken);
  const [auctionTabs, setAuctionTabs] = React.useState<IAuctionTab[]>([]);


  React.useEffect(() => {
    if(user){
      client?.service('staff-roles').find({
        headers: { Authorization: authToken },
        user: user,
        query: {
          userID: user?._id,
          expired: 'NA',
          $limit: 50
        },
      })
        .then((res: any) => res.data)
        .then((roles: IStaffRole[]) => {
          const bucket: IBucket = {};
          roles.forEach(role => {
            if(bucket[role.auctionID]){
              bucket[role.auctionID] = [
                ...bucket[role.auctionID],
                role
              ]
            } else {
              bucket[role.auctionID] = [
                role
              ]
            }
          })
          return Promise.all(Object.keys(bucket).map(async (auctionID: string) => {
            const auction = await client.service('auctions').get(auctionID, {
              headers: { Authorization: authToken },
              user: user
            });
            return {
              roles: bucket[auctionID],
              auction
            }
          }))
        })
        .then((auctionTabs: IAuctionTab[]) => {
          setAuctionTabs(auctionTabs.sort((a, b) => Number(new Date(b.auction.startDate)) - Number(new Date(a.auction.startDate))));
        });
    }
  }, [ user ]);


  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Auctions
        </ListSubheader>
      }
    >
      {auctionTabs.length > 0
        ?
          auctionTabs.map(tab => (
            <NestedTabsList tab={tab} key={tab.auction._id}/>
          ))
        : <Typography variant='body2' style={{ marginLeft: '30px' }}>None assigned</Typography>
      }
    </List>
  );
}