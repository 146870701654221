import React from 'react';
import {
  Table, TableBody, TableCell, 
  TableHead, TableRow,
  Collapse,
  IconButton,
} from '@mui/material';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { PurchaseBundle } from './index';


interface Props {
  data: PurchaseBundle,
  children?: JSX.Element,
}

export default function PaymentReportDetails ({ data, children }: Props): JSX.Element {
  const [expandOpen, setExpandOpen] = React.useState<boolean>(true);

  return (<>
    <TableRow hover style={{backgroundColor: '#f2f2f2'}}>
      <TableCell>
        <IconButton aria-label="expand row" size="small" onClick={() => setExpandOpen(!expandOpen)}>
          {expandOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </TableCell>
      {children}
    </TableRow>

    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={expandOpen} timeout="auto" unmountOnExit>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell><strong>Delivery Type</strong></TableCell>
                <TableCell><strong>Shipping Address</strong></TableCell>
                <TableCell><strong>Items</strong><strong style={{float: 'right'}}>Item Costs</strong></TableCell>
                <TableCell><strong>Shipping Cost</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.purchaseBundles.map((elt, index) => (
                <TableRow key={index}>
                  <TableCell>{elt.type}</TableCell>
                  <TableCell>{elt.address ? elt.address : '-'}</TableCell>
                  {/* Item Details */}
                  <TableCell>
                    {elt.items.map(item => (
                      <TableRow key={item.item._id} style={{width: '100%'}}>
                        <TableCell style={{width: '100%'}}>#{item.item.itemNumber} {item.item.title}</TableCell>
                        <TableCell style={{width: '20%'}}>${item.purchase.itemCost}</TableCell>
                      </TableRow>
                    ))}
                  </TableCell>
                  <TableCell>{elt.shippingCost ? '$' + elt.shippingCost : '-'}</TableCell>
                </TableRow>
              ))}
              <TableRow style={{height: 40}}></TableRow>
              <TableRow>
                <TableCell style={{borderBottom: 'none'}} colSpan={2}/>
                <TableCell><strong>Subtotal</strong></TableCell>
                <TableCell>${data.totalCost.toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{borderBottom: 'none'}} colSpan={2}/>
                <TableCell><strong>Service Charge</strong> (3%)</TableCell>
                <TableCell>{data.serviceCost ? '$' + data.serviceCost.toFixed(2) : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{borderBottom: 'none'}} colSpan={2}/>
                <TableCell><strong>Total</strong></TableCell>
                <TableCell><strong>${data.serviceCost ? (data.totalCost + data.serviceCost).toFixed(2) : data.totalCost.toFixed(2)}</strong></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  </>);
}