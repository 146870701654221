import * as React from 'react';
import { Button, TextField, Grid, Typography,
  CircularProgress, Divider, Dialog, 
  DialogActions, DialogTitle, DialogContent
} from '@mui/material';
import { IItem } from '../../Interfaces/Database/Item';
import { IBid } from '../../Interfaces/Database/Bid';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import client, { socket } from '../../client';
import { DollarIntegerFormatCustom, IntegerFormatCustom } from '../../helpers/number-format-custom';
import SensorsIcon from '@mui/icons-material/Sensors';
import { IAuction } from '../../Interfaces/Database/Auction';
import BidsList from './bids-list';

interface IProps {
  auction: IAuction,
  isMobile: boolean
}

interface IPopup {
  confirm: boolean,
  bid: null | IBid
}


export default function CurrentItem({ auction, isMobile }: IProps) {
  const user = useAppSelector((state: RootState) => state.root.user);
  const authToken = useAppSelector((state: RootState) => state.root.authToken);

  const [currentItem, setCurrentItem] = React.useState<IItem | null>(null);
  const [bidPriceInput, setBidPriceInput] = React.useState<number | null>();
  const [bidderNumberInput, setBidderNumberInput] = React.useState<number | null>();
  const [isCreatingBid, setIsCreatingBid] = React.useState<boolean>(false);
  const [createBidError, setCreateBidError] = React.useState<string>('');
  const [highestBid, setHighestBid] = React.useState<IBid | null>();
  const [minNextBid, setMinNextBid] = React.useState<number>();
  
  const [isSettingItemSold, setIsSettingItemSold] = React.useState<boolean>(false);
  const [itemSoldError, setItemSoldError] = React.useState<string>('');
  const [confirmSold, setConfirmSold] = React.useState<IPopup>({
    confirm: false,
    bid: null
  });

  const displayInfo = React.useMemo(() => {
    if(auction.state > 2){
      return { color: 'green', header: 'Auction Closed', subtext: `Closed at ${auction.endDate && new Date(auction.endDate).toLocaleString()}` };
    } else if(auction.currentLiveItem){
      return { color: 'red', header: 'Current Live Item', subtext: ''};
    } else if(auction.pausedUntil){
      return { color: 'black', header: 'Auction Paused', subtext: `Resuming at ${new Date(auction.pausedUntil).toLocaleString()}` };
    } else {
      return { color: 'yellow', header: 'Future Auction', subtext: `Opening at ${new Date(auction.startDate).toLocaleString()}` };
    }
  }, [ auction ]);

  React.useEffect(() => {
    getCurrentItem(auction);
  }, [ auction ]);

  React.useEffect(() => {
    socket
    .on('items patched', async (item: IItem) => {
      if(item._id === currentItem?._id){
        setCurrentItemHelper(item);
      }
    })
  }, [currentItem]);

  const setCurrentItemHelper = async (item: IItem | null) => {
    setCurrentItem(item);
    let bid: IBid | null = null;
    if(item && item.highestBidID){
      bid = await client?.service('bids').get(item.highestBidID, {
        headers: { Authorization: authToken },
        user: user
      });
    }
    setHighestBid(bid);
    if(item){
      setMinNextBid(
        bid && bid.price
        ? bid.price + item.bidIncrement
        : item.startingBid
      );
    }
  }


  const getCurrentItem = async (auc: IAuction) => {
    if(auc.currentLiveItem){
      client?.service('items').get(auc.currentLiveItem, {
        headers: { Authorization: authToken },
        user: user
      }).then(async (item: IItem) => {
        setCurrentItemHelper(item);
      });
    } else {
      setCurrentItemHelper(null);
    }
  }

  const submitBid = async () => {
    setCreateBidError('');

    if(!bidderNumberInput || !bidPriceInput){
      setCreateBidError('Bidder number and bid price are required');
      return;
    }

    setIsCreatingBid(true);

    client?.service('bids').create({
      bidderNumber: bidderNumberInput,
      isCreatedLive: true,
      itemID: currentItem?._id.toString(),
      price: bidPriceInput,
      initalTimeSet: Date.now(),
      _testingAuction: auction.isTesting
    }, {
      headers: { Authorization: authToken },
      user: user
    })
      .then(() => {
        setBidPriceInput(parseInt(''));
        setBidderNumberInput(parseInt(''));
      })
      .catch((error: any) => {
        setCreateBidError(error.message);
      })
      .finally(() => setIsCreatingBid(false));
 }

 const setItemAsSold = () => {
  setIsSettingItemSold(true);
  setItemSoldError('');
  client.service('purchases').create({
    userID: confirmSold.bid?.userID,
    bidderNumber: confirmSold.bid?.bidderNumber,
    itemID: confirmSold.bid?.itemID,
    auctionID: auction._id,
    itemCost: confirmSold.bid?.price
  }, {
    headers: { Authorization: authToken },
    user: user
  })
    .then(() => setConfirmSold({confirm: false, bid: null}))
    .catch((err: any) => {
      console.log('error: ', err);
      setItemSoldError(err.message);
    })
    .finally(() => setIsSettingItemSold(false));
 }


  return (
    <div style={{ height: '100%' }}>

      <Dialog open={confirmSold.confirm}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          {confirmSold.bid?._id !== currentItem?.highestBidID &&
            <Typography style={{ fontStyle: 'italic', color: 'red' }}>* This is not the highest bid *</Typography>
          }
          <Typography style={{ marginBottom: '10px' }}>You are setting the current live item as sold to the following:</Typography>
          <Typography>Bidder #{confirmSold.bid?.bidderNumber}</Typography>
          <Typography>Price: ${confirmSold.bid?.price}</Typography>
        </DialogContent>
        <DialogActions>
          <Typography color={'error'}>{itemSoldError}</Typography>
          <Button onClick={() => {
            setConfirmSold({confirm: false, bid: null});
            setItemSoldError('');
          }} color="primary">Cancel</Button>
          <Button variant="contained" onClick={setItemAsSold} disabled={isSettingItemSold}>
            {isSettingItemSold
              ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
              : 'Continue'
            }
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ margin: '5px' }}>
        <SensorsIcon fontSize='large' style={{ display: 'inline', color: displayInfo.color, verticalAlign: 'middle'}}/>
        <Typography display="inline" variant='h5' style={{ margin: '10px 10px', verticalAlign: 'middle' }}>{displayInfo.header}</Typography>
      </div>
      <Divider/>
      <Grid container style={{ height: '100%' }}>
        {/* Item details */}
        <Grid item xs={12} md={6} style={{ padding: '10px' }}>
          {currentItem  
            ? <>
              <Typography display="inline" variant='h6'><strong># {currentItem.itemNumber}</strong></Typography>
              <Typography display="inline" style={{ marginLeft: '25px' }}><em>Category:</em> {currentItem.category}</Typography>
              {currentItem.isSold &&
                <Typography 
                  variant='h6' 
                  style={{ margin: '10px 0', padding: '2px', border: '2px solid red', borderRadius: '5px', width: 'fit-content' }} 
                  color={'error'}
                ><strong>SOLD:</strong>&nbsp;&nbsp;Bidder: #{currentItem.purchaseBidder}&nbsp;&nbsp;Price: ${currentItem.purchasePrice}
                </Typography>
              }
              <Typography style={{ margin: '10px 0' }}><em>Title:</em>  {currentItem.title}</Typography>
              <Divider><em>Details</em></Divider>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography style={{ marginBottom: '10px', fontWeight: 'bold' }}>Starting bid: ${currentItem.startingBid}</Typography>
                  <Typography style={{ marginBottom: '10px' }}>Bid Increment: ${currentItem.bidIncrement}</Typography>
                  <Typography>{currentItem.description}</Typography>
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: '0 20px' }}>
                  <img 
                    src={`data:image/png;base64,${currentItem.pictures[0]}`} 
                    alt={currentItem.title} 
                    style={{width: '100%', objectFit: 'contain'}}
                  />
                </Grid>
              </Grid>
            </>
            : <Typography>{displayInfo.subtext}</Typography>
          }
        </Grid>

        {auction.currentLiveItem &&
          <Grid item xs={12} md={6} style={{ height: '100%', padding: '10px' }}>
            <BidsList
              currentItem={currentItem}
              itemID={currentItem?._id}
              isMobile={isMobile}
              onRowClick={(bid: IBid) => setConfirmSold({confirm: true, bid: bid})}
            />
            
            {/* input bids */}
            <div style={{ height: '15%' }}>
              <Grid container>
                <TextField
                  label={'Bidder Number'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant='outlined'
                  value={bidderNumberInput}
                  onChange={value => setBidderNumberInput(parseInt(value.target?.value))}
                  InputProps={{ inputMode: 'numeric', inputComponent: IntegerFormatCustom as any }}
                  disabled={isCreatingBid || currentItem?.isSold}
                  sx={isMobile ? { '& .MuiInputLabel-root': { zIndex: 0 }, width: '100%' , marginBottom: '10px', marginTop: '10px' } : { '& .MuiInputLabel-root': { zIndex: 0 } }}
                />

                <TextField
                  variant='outlined'
                  label={'Bid Price'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={minNextBid ? `$${minNextBid.toString()} or higher` : ''}
                  value={bidPriceInput}
                  InputProps={{ inputMode: 'numeric', inputComponent: DollarIntegerFormatCustom as any }}
                  onChange={value => setBidPriceInput(parseInt(value.target?.value))}
                  disabled={isCreatingBid || currentItem?.isSold}
                  sx={isMobile ? { '& .MuiInputLabel-root': { zIndex: 0 }, width: '100%' , marginBottom: '10px' } : { '& .MuiInputLabel-root': { zIndex: 0 } }}
                />

                <Button variant="contained" onClick={submitBid} disabled={isCreatingBid || currentItem?.isSold} fullWidth={isMobile}>
                  {isCreatingBid
                    ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
                    : 'Submit'
                  }
                </Button>
              </Grid>
              <Typography color='red' align='right'>{createBidError}</Typography>
            </div>
          </Grid>
        }
      </Grid>
    </div>
  );
}
