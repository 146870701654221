import { Container,Typography, TextField, Button, CircularProgress, Grid, fabClasses } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import React from 'react';
import client from '../../client';
import { IUser } from '../../Interfaces/Database/User';
import SearchResults from './search-results';
import AddUserDialog from './add-user-dialog';

interface Props {
  auctionID: string | undefined
}

export interface IUserCheckedIn extends IUser {
  checkedIn: boolean,
}

export interface IUserCheckedInMap {
  [userID: string]: IUserCheckedIn
}



export default function Search({ auctionID }: Props) {
  const user = useAppSelector((state: RootState) => (state.root as any).user);
  const authToken = useAppSelector((state: RootState) => (state.root as any).authToken);

  const [addUserDialogOpen, setAddUserDialogOpen] = React.useState<boolean>(false);
  const [firstNameSearch, setFirstNameSearch] = React.useState<string>();
  const [lastNameSearch, setLastNameSearch] = React.useState<string>();
  const [emailSearch, setEmailSearch] = React.useState<string>();
  const [bidderNumberSearch, setBidderNumberSearch] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [results, setResults] = React.useState<IUserCheckedInMap | undefined>();


  const search = async () => {
    setIsLoading(true);
    let query: any = {
      $limit: 50,
      isStaff: false
    };
    if(firstNameSearch){
      query = { ...query, firstNameSearch: firstNameSearch.toLowerCase().trim() };
    }
    if(lastNameSearch){
      query = { ...query, lastNameSearch: lastNameSearch.toLowerCase().trim() };
    }
    if(emailSearch){
      query = { ...query, email: emailSearch.toLowerCase().trim() };
    }
    if(bidderNumberSearch){
      query = { ...query, bidderNumber: parseInt(bidderNumberSearch) };
    }
    
    const filteredUsers: IUser[] = await client?.service('users').find({
      headers: { Authorization: authToken },
      user: user,
      query
    }).then((res: any) => res.data);

    const usersCheckedInMap: IUserCheckedInMap = {};
    await Promise.all(filteredUsers.map(async filteredUser => {
      const checkedIn = await client?.service('check-in').find({
        headers: { Authorization: authToken },
        user: user,
        query: {
          userID: filteredUser._id,
          auctionID
        }
      }).then((res: any) => {
        return res.data.length > 0 ? true : false
      });
      usersCheckedInMap[filteredUser._id] = {
        ...filteredUser,
        checkedIn: checkedIn
      }
    }));
    setResults(usersCheckedInMap);
    setIsLoading(false);
  };

  const clearFilters = () => {
    setFirstNameSearch('');
    setLastNameSearch('');
    setEmailSearch('');
    setBidderNumberSearch('');
    setResults(undefined);
  };

  const updateCheckedInMap = (userID: string) => {
    const newUsersCheckedInMap = { ...results };
    newUsersCheckedInMap[userID] = {
      ...newUsersCheckedInMap[userID],
      checkedIn: true
    };
    setResults(newUsersCheckedInMap);
  };

  const handleAfterUserCreate = (user: IUser) => {
    setEmailSearch(user.email);
    setResults({[user._id]: {
      ...user,
      checkedIn: false
    }});
  }


  return (
    <Container style={{ marginTop: '20px' }}>
      <AddUserDialog
        open={addUserDialogOpen}
        initialFirstName={firstNameSearch}
        initialLastName={lastNameSearch}
        initialEmail={emailSearch}
        onClose={() => setAddUserDialogOpen(false)}
        onCreate={handleAfterUserCreate}
      />
      <Grid container>
        <Grid item xs={12} md={6}>
          <div style={{ margin: '15px' }}>

            <TextField
              fullWidth
              value={firstNameSearch}
              style={{ display: 'block', margin: '10px' }}
              id="first-name"  label={'First Name'} variant="outlined"
              InputLabelProps={{ shrink: true }} 
              onChange={(e: any) => setFirstNameSearch(e.target.value)}
            />
            <TextField
              fullWidth
              value={lastNameSearch}
              style={{ display: 'block', margin: '10px' }}
              id="last-name" label={'Last Name'} variant="outlined"
              InputLabelProps={{ shrink: true }} 
              onChange={(e: any) => setLastNameSearch(e.target.value)}
            />
            <TextField 
              fullWidth
              value={emailSearch}
              style={{ display: 'block', margin: '10px' }}
              id="email" label={'Email'} variant="outlined"
              InputLabelProps={{ shrink: true }} 
              onChange={(e: any) => setEmailSearch(e.target.value)}
            />
            <TextField 
              fullWidth
              value={bidderNumberSearch}
              style={{ display: 'block', margin: '10px' }}
              id="bidder-number" label={'Bidder Number'} variant="outlined"
              InputLabelProps={{ shrink: true }}  
              onChange={(e: any) => setBidderNumberSearch(e.target.value)}
            />
            <Button variant="contained" onClick={search} disabled={isLoading} style={{ margin: '10px', display: 'inline-block' }}>
              {isLoading
                ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
                : 'Search'
              }
            </Button>
            <Button variant='outlined' onClick={clearFilters} style={{ margin: '10px', display: 'inline-block' }}>
              Clear
            </Button>
            <Button variant='contained' color='secondary' onClick={() => {
              setAddUserDialogOpen(true)
            }} style={{ margin: '10px', display: 'inline-block' }}>
              Add New User
            </Button>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div style={{ margin: '15px' }}>
            {results !== undefined &&
              Object.values(results).length > 0 
                ? <SearchResults users={results} auctionID={auctionID} onCreate={(userID: string) => updateCheckedInMap(userID)}/>
                : <Typography>No Results</Typography>
            }
          </div>
        </Grid>

      </Grid>

    </Container>
  );
}