import { useParams } from 'react-router-dom';
import { Box, Container, Tabs, Tab, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import React from 'react';
import Search, { IUserCheckedIn } from './search';
import CheckInList from './check-in-list';
import checkIn from '.';
import client from '../../client';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import AddCard from './add-card';

export interface ICard {
  cardBrand: string,
  lastFour: string, 
  expire: string,
}


interface IProps {
  open: boolean,
  auctionID: string | undefined,
  userToCheckIn: IUserCheckedIn,
  onClose: () => void,
  onCreate: (userID: string) => void,
}

export default function CardInfoDialog({ open, auctionID, userToCheckIn, onClose, onCreate }: IProps) {
  const user = useAppSelector((state: RootState) => (state.root as any).user);
  const authToken = useAppSelector((state: RootState) => (state.root as any).authToken);

  const [isLoadingCheckIn, setIsLoadingCheckIn] = React.useState<boolean>(false);
  const [updateCard, setUpdateCard] = React.useState<boolean>(false);
  const [isLoadingCardInfo, setIsLoadingCardInfo] = React.useState<boolean>(false);
  const [currentCard, setCurrentCard] = React.useState<ICard | null | undefined>(undefined);
  const [error, setError] = React.useState<string>('');

  const resetDialog = () => {
    setCurrentCard(null);
    setIsLoadingCardInfo(false);
    onClose();
  };

  React.useEffect(() => {
    setIsLoadingCardInfo(true);
    client?.service('square-cards').get(userToCheckIn.customerID, {
      Authorization: authToken,
      user,
    })
      .then((res: any) => {
        setError('');
        setCurrentCard(res ? res : null);
      })
      .catch((err: any) => {
        console.log('cards err: ', err);
        setError(err.message);
        setCurrentCard(null);
      })
      .finally(() =>  setIsLoadingCardInfo(false));
  }, [ userToCheckIn ]);

  const checkIn = () => {
    setIsLoadingCheckIn(true);
    client?.service('check-in').create({
      userID: userToCheckIn._id, 
      firstName: userToCheckIn.firstName,
      lastName: userToCheckIn.lastName,
      email: userToCheckIn.email,
      bidderNumber: userToCheckIn.bidderNumber,
      auctionID
    }, {
      headers: { Authorization: authToken },
      user
    }).then(() => {
      onCreate(userToCheckIn._id);
      onClose();
    }).catch((err: any) => console.log('err: ', err))
      .finally(() => setIsLoadingCheckIn(false));
  };

  return (
    <Dialog open={open}>
        <DialogTitle>Complete Check In</DialogTitle>
        <DialogContent>
          <Typography style={{ marginBottom: '10px' }}>Name: {userToCheckIn?.firstName} {userToCheckIn?.lastName}</Typography>
          
          {isLoadingCardInfo
            ? <Grid container style={{ marginTop: '30px', display: 'flex', height: '100%' }}>
                <CircularProgress style={{ margin: 'auto' }} />
              </Grid>
            : <>
                {currentCard 
                  ?
                    <div>
                      <Typography style={{ fontWeight: 'bold' }}>Card on file:</Typography>
                      <div style={{margin: '0 auto 10px', padding: '10px', backgroundColor: '#eff2f5', borderRadius: '5px' }}>
                        <Grid container>
                            <Grid item xs={12}>
                              <img 
                                style={{ width: '70px', height: '40px' }}
                                src={require(`../../static-assets/images/card-type-${currentCard.cardBrand}.png`)}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant='caption'>Number:</Typography>
                              <Typography>****{currentCard.lastFour}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant='caption'>Expires:</Typography>
                              <Typography>{currentCard.expire}</Typography>
                            </Grid>
                          </Grid>
                      </div>
                      {updateCard
                        ? <AddCard currentCard={currentCard} customer={userToCheckIn} setCurrentCard={setCurrentCard}/>
                        : <>
                            <Button variant="outlined"  onClick={() => setUpdateCard(true)}>Update Card</Button>
                            <Typography style={{ fontStyle: 'italic' }}>Confirm the card on file with the bidder before checking in.</Typography>
                          </>
                      }
                    </div>
                  : <AddCard currentCard={currentCard} customer={userToCheckIn} setCurrentCard={setCurrentCard}/>
                }
                
            </>
          }
          
        </DialogContent>
        <DialogActions>
          <Typography color={'error'}>{error}</Typography>
          <Button onClick={resetDialog} color="primary">Cancel</Button>
          <Button variant="contained" onClick={checkIn} disabled={!currentCard}>
              {isLoadingCheckIn
                ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
                : 'Check in'
              }
            </Button>
        </DialogActions>
      </Dialog>
  );
}