import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box, Button, Dialog, DialogActions, DialogTitle, FormControl, Grid, InputAdornment, 
  InputLabel, OutlinedInput, Select, TextField, Typography, CircularProgress
} from '@mui/material';
import React from 'react';
import { IItem, ShippingType, SHIPPING_TYPE } from '../../Interfaces/Database/Item';
import ItemImages from './item-images';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import { addAsterickOnError } from '../../helpers/accessibility';
import { ICategoryMap } from './item-detail-wrapper';
import { useMutation } from 'react-query';
import client from '../../client';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';

interface IProps {
  item: IItem | undefined,
  categories: ICategoryMap,
}

export default function ItemDetail({ item, categories }: IProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector((state: RootState) => state.root.user);
  const authToken = useAppSelector((state: RootState) => state.root.authToken);

  const auctionID = React.useMemo(() => {
    return location.pathname.split('/')[1];
  }, [ location ]);

  const titleRef = React.createRef<HTMLInputElement>();
  const descriptionRef = React.createRef<HTMLInputElement>();
  const startingBidRef = React.createRef<HTMLInputElement>();
  const bidIncrementRef = React.createRef<HTMLInputElement>();
  const shippingCostRef = React.createRef<HTMLInputElement>();

  const [images, setImages] = React.useState<string[]>(item ? item.pictures : []);
  const [category, setCategory] = React.useState<string | undefined>(item ? item.categoryID : undefined);
  const [shippingType, setShippingType] = React.useState<string>(item ? item.shippingType : 'No Shipping');
  const [shippingCost, setShippingCost] = React.useState<number | undefined>(item ? item.shippingCost : undefined);

  const [inputChanged, setInputChanged] = React.useState<boolean>(false);
  const [needConfirm, setNeedConfirm] = React.useState<boolean>(false);
  const [categoryChanged, setCategoryChanged] = React.useState<boolean>(false);
  const [titleError, setTitleError] = React.useState<boolean>(false);
  const [categoryError, setCategoryError] = React.useState<boolean>(false);
  const [descriptionError, setDescriptionError] = React.useState<boolean>(false);
  const [startingBidError, setStartingBidError] = React.useState<boolean>(false);
  const [bidIncrementError, setBidIncrementError] = React.useState<boolean>(false);
  const [shippingCostError, setShippingCostError] = React.useState<boolean>(false);
  const [inputValidationError, setInputValidationError] = React.useState<string>('');

  const inputChange = (ref: React.RefObject<HTMLInputElement>, callback: (state: boolean) => void) => {
    if (!ref.current?.value) {
      callback(true);
    }
    else { //check if white space
      if (ref.current.value.trim().length === 0){
        callback(true);
      }
      else callback(false);
    }
    if (!inputChanged) setInputChanged(true);
  }

  const checkAllFields = () => {
    let valid = true;

    if (inputChanged) {
      if (titleError) valid = false;
      else if (descriptionError) valid = false;
      else if (categoryError) valid = false;
      else if (startingBidError) valid = false;
      else if (bidIncrementError) valid = false;
      else if (shippingCostError) valid = false;
      else if (!item && !categoryChanged) valid = false;
      else if (!images || images.length === 0) valid = false;
    }
    if (!valid) setInputValidationError('(*) All fields must be filled out');
    else if (valid && inputChanged) {
      let startBid = (startingBidRef.current as any).value;
      if(parseInt(startBid) !== parseFloat(startBid)){
        setStartingBidError(true);
        setInputValidationError('(*) Starting Bid must be a full dollar amount');
        return;
      }
      setInputValidationError('');
      onSave();
    }
  }

  const onSave = () => {
    let shipping = shippingCostRef.current ? parseFloat(shippingCostRef.current.value) : null;
    if(shippingType === 'No Shipping') shipping = -1;

    let i: IItem = {
      auctionID,
      isSold: false,
      categoryID: category,
      category: categories[category as string],
      title: (titleRef.current as any).value,
      description: (descriptionRef.current as any).value,
      pictures: images,
      startingBid: (startingBidRef.current as any).value,
      bidIncrement: (bidIncrementRef.current as any).value,
      isBuyNow: false,
      shippingType,
      shippingCost: shipping,
      isClosed: false,
    } as any;

    itemMutation.mutate(i);
  };

  const itemMutation = useMutation((itemToMutate: IItem) => {
    if(item){
      return client?.service('items').patch(item._id, itemToMutate, {
        headers: { Authorization: authToken },
        user
      })
    } else {
      return client?.service('items').create(itemToMutate, {
        headers: { Authorization: authToken },
        user
      })
    }
  }, {
    onSuccess: () => {
      setInputChanged(false);
      navigate(-1);
    }
  });


  const cancelCheckIfEdit = () => {
    if (inputChanged) {
      setNeedConfirm(true);
    }
    else{
      navigate(-1);
    } 
  }

  return (
    <React.Fragment>

      <Dialog open={needConfirm}>
        <DialogTitle>Are you sure you want to leave? No changes will be saved.</DialogTitle>
        <DialogActions>
          <Button onClick={() => {setNeedConfirm(false); setInputChanged(false); navigate(-1);}} color="primary">Yes</Button>
          <Button onClick={() => setNeedConfirm(false)} color="primary">No, Continue Editing</Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: 'auto' }}>
        <Button variant="contained" onClick={cancelCheckIfEdit}>
          <ArrowBackIcon/>&nbsp;Back
        </Button>
        <div style={{ display: 'flex' }}>
          <Typography color={'error'} style={{ marginRight:'10px' }}>{inputValidationError}</Typography>
          {itemMutation.isError &&
            <Typography color={'error'} style={{ marginRight:'10px' }}>An error occurred</Typography>
          }
          <Button variant="contained" onClick={checkAllFields} disabled={!inputChanged || itemMutation.isLoading}>
            {itemMutation.isLoading
              ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
              : <><SaveIcon/>&nbsp;Save</>
            }
          </Button>
        </div>
      </Box>
        
        <Grid container spacing={2} style={{ marginTop: '10px' }}>

          <Grid item xs={12} lg={6}>
            <ItemImages 
              itemImages={images} 
              altName={item?.title}
              isUpdate={true}
              onChange={() => { if(!inputChanged) setInputChanged(true) }}
              sendImages={(images: string[]) => setImages(images) }
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField 
                  id="title" 
                  label={addAsterickOnError(titleError, 'Title')}
                  variant="outlined"  
                  multiline rows={4}  
                  style={{ width: '100%' }}
                  inputRef={titleRef}
                  defaultValue={item ? item.title : ''}
                  error={titleError}
                  onChange={() => inputChange(titleRef, (state: boolean): void => { setTitleError(state); })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  id="description" 
                  label={addAsterickOnError(descriptionError, 'Description')} 
                  variant="outlined" 
                  multiline rows={4} 
                  style={{ width: '100%' }}
                  inputRef={descriptionRef}
                  defaultValue={item ? item.description : ''}
                  error={descriptionError}
                  onChange={() => inputChange(descriptionRef, (state: boolean): void => { setDescriptionError(state); })}
                />
              </Grid>
              
              <Grid item xs={12}>
                <FormControl variant="outlined"  style={{ width: '100%' }}>
                  <InputLabel>Category</InputLabel> 
                  <Select
                    native
                    label={addAsterickOnError(categoryError, 'Category')}
                    error={categoryError}
                    value={category}
                    onChange={e => {
                      console.log('e: ', e.target.value)
                      setCategory(e.target.value as string);
                      setCategoryError(!e.target.value)
                      if (!inputChanged) setInputChanged(true);
                      if (!categoryChanged) setCategoryChanged(true);
                    }}
                  >
                    {item === undefined && 
                      <option value={undefined} disabled selected>Select an option</option>
                    }
                    {categories && Object.keys(categories).map(index =>
                      <option style={{ cursor: 'pointer' }} value={index}>{categories[index]}</option>, 
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel htmlFor="startingBid">{addAsterickOnError(startingBidError, 'Starting Bid')}</InputLabel>
                  <OutlinedInput
                    type='number'
                    id="startingBid"
                    error={startingBidError}
                    inputRef={startingBidRef}
                    defaultValue={item ? item.startingBid : undefined}
                    onChange={e => {
                      setStartingBidError(!e.target.value || parseInt(e.target.value) < 0)
                      if (!inputChanged) setInputChanged(true);
                    }}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label={addAsterickOnError(startingBidError, 'Starting Bid')}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel htmlFor="bidIncrement">{addAsterickOnError(bidIncrementError, 'Bid Increment')}</InputLabel>
                  <OutlinedInput
                    type='number'
                    id="bidIncrement"
                    error={bidIncrementError}
                    inputRef={bidIncrementRef}
                    defaultValue={item ? item.bidIncrement : undefined}
                    onChange={e => {
                      setBidIncrementError(!e.target.value || parseInt(e.target.value) < 0)
                      if (!inputChanged) setInputChanged(true);
                    }}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label={addAsterickOnError(bidIncrementError, 'Bid Increment')}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel>Shipping Type</InputLabel> 
                  <Select
                    native
                    label='Shipping Type'
                    value={shippingType}
                    onChange={event => {
                      setInputChanged(true);
                      setShippingType(event.target.value as ShippingType);
                      setShippingCostError(
                        (event.target.value as ShippingType) !== 'No Shipping' && !shippingCost
                        ? true : false
                      );
                    }}
                  >
                    {SHIPPING_TYPE.map(type => 
                        <option style={{ cursor: 'pointer' }} value={type}>{type}</option>, 
                    )}
                  </Select>
                </FormControl>
              </Grid>

              {shippingType !== 'No Shipping' &&
                <Grid item xs={12}>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel htmlFor="shipppingCost">Shipping Cost</InputLabel>
                    <OutlinedInput
                      type='number'
                      id="shipppingCost"
                      error={shippingCostError}
                      inputRef={shippingCostRef}
                      defaultValue={item && item.shippingCost >= 0 ? item.shippingCost : undefined}
                      onChange={e => {
                        setShippingCostError(!e.target.value || parseInt(e.target.value) < 0)
                        if (!inputChanged) setInputChanged(true);
                      }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Shipping Cost"
                    />
                  </FormControl>
                  {/* <TextField
                    style={{ width: '100%' }}
                    variant='outlined'
                    label="Shipping Cost"
                    error={shippingCostError}
                    inputRef={shippingCostRef}
                    value={shippingCost}
                    InputProps={{ inputComponent: DollarIntegerFormatCustom as any }}
                    onChange={value => {
                      setShippingCost(parseFloat(value.target?.value));
                      inputChange(shippingCostRef,  (state: boolean): void => { setShippingCostError(state) }); 
                    }}
                  /> */}
                </Grid>
              }
            </Grid>

          </Grid>
        </Grid>
      </React.Fragment>
  );
}