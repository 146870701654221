import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, CircularProgress } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import client from '../../client';
import { addAsterickOnError } from '../../helpers/accessibility';
import PhoneNumberFormat from '../../helpers/phone-number-format';
import { IUser } from '../../Interfaces/Database/User';

export interface ICard {
  cardBrand: string,
  lastFour: string, 
  expire: string,
}


interface IProps {
  open: boolean,
  initialFirstName: string | undefined, 
  initialLastName: string | undefined, 
  initialEmail: string | undefined,
  onClose: () => void,
  onCreate: (user: IUser) => void
}

export default function AddUserDialog({ open, initialFirstName, initialLastName, initialEmail, onClose, onCreate }: IProps) {
  const user = useAppSelector((state: RootState) => (state.root as any).user);
  const authToken = useAppSelector((state: RootState) => (state.root as any).authToken);

  const [firstName, setFirstName] = React.useState<string | undefined>(initialFirstName);
  const [lastName, setLastName] = React.useState<string | undefined>(initialLastName);
  const [email, setEmail] = React.useState<string | undefined>(initialEmail);
  const [phoneNumber, setPhoneNumber] = React.useState<string>();
  const [address, setAddress] = React.useState<string>();
  const [error, setError] = React.useState<string>();

  const [firstNameError, setFirstNameError] = React.useState<boolean>(false);
  const [lastNameError, setLastNameError] = React.useState<boolean>(false);
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = React.useState<boolean>(false);
  const [addressError, setAddressError] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const resetErrorStates = () => {
    setError('');
    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
    setPhoneNumberError(false);
    setAddressError(false);
  }

  const resetDialog = () => {
    resetErrorStates();
    setFirstName(undefined);
    setLastName(undefined);
    setEmail(undefined);
    setPhoneNumber(undefined);
    setAddress(undefined);
    onClose();
  };

  const handleRegister = () => {
    resetErrorStates();

    if(!firstName){
      setFirstNameError(true);
    }
    if(!lastName){
      setLastNameError(true);
    }
    if(!email){
      setEmailError(true);
    }
    if(!phoneNumber){
      setPhoneNumberError(true);
    }
    if(!address){
      setAddressError(true);
    }
    if(!firstName || !lastName || !email || !phoneNumber || !address){
      setError('(*) fields are required');
      return;
    }

    setIsLoading(true);
    client.service('users').create({
      email: email.toLocaleLowerCase(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phoneNumber,
      address: address.trim(),
    }, {
      headers: { Authorization: authToken },
      user: user
    })
      .then((user: IUser) => {
        resetDialog();
        onCreate(user);
      })
      .catch((err: any) => {
        console.log('Create User Error:', err);
        if (err.code === 409 || err.message === 'User Email Already Exists') {
          setError('(*) Email Already Exists');
          setEmailError(true);
        } else {
          setError('(*) An error has occurred');
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Dialog open={open}>
        <DialogTitle>Register a New User</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={firstName}
            style={{ marginBottom: '20px', marginTop: '10px' }}
            id="first-name"  label={addAsterickOnError(firstNameError, 'First Name')} variant="outlined"
            InputLabelProps={{ shrink: true }}
            error={firstNameError}
            onChange={(e: any) => setFirstName(e.target.value)}
          />
          <TextField
            fullWidth
            value={lastName}
            style={{ marginBottom: '20px' }}
            id="last-name"  label={addAsterickOnError(lastNameError, 'Last Name')} variant="outlined"
            InputLabelProps={{ shrink: true }}
            error={lastNameError}
            onChange={(e: any) => setLastName(e.target.value)}
          />
          <TextField
            style={{ marginBottom: '20px' }}
            label={addAsterickOnError(emailError, 'Email')}
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={email}
            onChange={e => setEmail(e.target.value)}
            error={emailError}
          />
          <TextField
            style={{ marginBottom: '20px' }}
            label={addAsterickOnError(phoneNumberError, 'Phone Number')}
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            InputProps={{
              inputComponent: PhoneNumberFormat as any,
            }}
            error={phoneNumberError}
          />
          <TextField
            fullWidth
            value={address}
            style={{ marginBottom: '20px' }}
            id="address"  label={addAsterickOnError(addressError, 'Address')} variant="outlined"
            InputLabelProps={{ shrink: true }}
            error={addressError}
            onChange={(e: any) => setAddress(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Typography color={'error'}>{error}</Typography>
          <Button onClick={resetDialog} color="primary">Cancel</Button>
          <Button variant="contained" onClick={handleRegister}>
              {isLoading
                ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
                : 'Create'
              }
            </Button>
        </DialogActions>
      </Dialog>
  );
}