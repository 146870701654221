import { useParams } from 'react-router-dom';
import { Container, Paper, Typography } from '@mui/material';
import React from 'react';
import client from '../client';
import { useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import { IAuction } from '../Interfaces/Database/Auction';
import '../style/auction-details.css';


export default function AuctionDetails() {
  let { auctionID } = useParams();
  const user = useAppSelector((state: RootState) => state.root.user);
  const authToken = useAppSelector((state: RootState) => state.root.authToken);

  const [auction, setAuction] = React.useState<IAuction>();

  React.useEffect(() => {
    client.service('auctions').get(auctionID, {
      headers: { Authorization: authToken },
      user: user
    })
      .then((res: IAuction) => setAuction(res));
  }, [auctionID]);


  return (
    <Container>
      {auction &&
        <Paper style={{ margin: '15px 0' }}>
          <div style={{
            width: 'fit-content',
            margin: 'auto',
            padding: '10px'
          }}>
                
            <Typography className="text" style={{padding: 10}}variant="h5">{auction.name} Auction</Typography> 
            {auction.charityName &&
              <Typography className="charityText" variant="h6">{auction.charityName} Charity</Typography> 
            }
            <div>
              <Typography className="title" variant="subtitle1">Auction Type:</Typography>
              <Typography className="description" variant="subtitle1">
                {auction.type === 'liveAndOnline' ? 'Live + Online' : 'Online'}
                {auction.isTesting ? ' (INTERNAL TESTING)' : ''}
              </Typography>
            </div>
            {auction.charityDescription &&
              <div>
                <Typography className="title" variant="subtitle1">Charity Info:</Typography>
                <Typography className="description" variant="subtitle1">{auction.charityDescription}</Typography>
              </div>
            }

            {auction.type === 'liveAndOnline' && auction.liveLocation &&
              <div>
                <Typography className="title" variant="subtitle1">Location:</Typography>
                <Typography className="description" variant="subtitle1">{auction.liveLocation}</Typography>
              </div>
            }
            <div>
              <Typography className="title" variant="subtitle1">Time:</Typography>
              <Typography className="description">{auction.state <=1 ? 'Opens: ' : 'Opened: '} {new Date(auction.startDate as any).toLocaleString()}</Typography>
              {auction.endDate && 
                <Typography className="description">,&emsp;{auction.state <= 2 ? 'Closes: ' : 'Closed: ' }{new Date(auction.endDate as any).toLocaleString()}</Typography>
              }
            </div>
            {auction.type === 'liveAndOnline' && auction.preBiddingEnabled &&
              <div>
                <Typography className="title">Pre-Bidding:</Typography>
                {auction.preBiddingOpen 
                  ? <Typography className="description">OPEN NOW&emsp;Ends: {new Date(auction.preBiddingEnd as any).toLocaleString()}</Typography>
                  : <Typography className="description">
                      {new Date(auction.preBiddingStart as any) > new Date() ? 'Starts: ' : 'Started: '} {new Date(auction.preBiddingStart as any).toLocaleString()},&emsp;
                      {new Date(auction.preBiddingEnd as any) > new Date() ? 'Ends: ' : 'Ended: ' } {new Date(auction.preBiddingEnd as any).toLocaleString()}
                    </Typography>
                }
              </div>
            }
            {auction.additionalInformation &&
              <div style={{  marginTop: '10px' }}>
                <Typography className="title" style={{ fontStyle: 'italic', }}>Additional Info:</Typography>
                <Typography className="description">{auction.additionalInformation}</Typography>
              </div>
            }
          </div>
        </Paper>
      }
    </Container>
  );
}