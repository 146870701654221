import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import React from 'react';
import logoImage from '../../static-assets/images/logo-transparent.png';

import { useAppDispatch } from '../../app/hooks';
import { IUser } from '../../Interfaces/Database/User';
import { setUser } from '../../global-state/root-slice';
import client from '../../client';
import { addAsterickOnError } from '../../helpers/accessibility';
import { useLocation, useNavigate } from 'react-router-dom';
import { addCookie } from '../../helpers/cookie';


interface IAuthResponse {
  accessToken: string,
  authentication: { strategy: string },
  user: IUser,
}


export default function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let location = useLocation();
  //@ts-ignore
  let from = location.state?.from?.pathname || "/";
  // const client = useAppSelector((state: RootState) => state.root.client);
  
  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const [forgotPasswordOpen, setForgotPasswordOpen] = React.useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [usernameError, setUsernameError] = React.useState<boolean>(false);
  const [passwordError, setPasswordError] = React.useState<boolean>(false);

  const handleSubmit = () => {
    setUsernameError(false);
    setPasswordError(false);
    setErrorMessage('');
    let valid = true;

    if(!username){
      setUsernameError(true);
      setErrorMessage('(*) Field required');
      valid = false;
    }
    if(!password){
      setPasswordError(true);
      setErrorMessage('(*) Field required');
      valid = false;
    }

    if(valid){
      setIsLoading(true);

      client?.service('authentication').create({
        email: username.toLocaleLowerCase() + '.staff',
        password, 
        strategy: 'local'
      })
        .then((res: IAuthResponse) => {
          dispatch(setUser({ 
            user: res.user, 
            authToken: res.accessToken 
          }));
          localStorage.setItem('s-token', res.accessToken);
          const expiration = new Date(Date.now() + 1000 * 60 * 60 * 24 * 7);
          addCookie('s-accessToken', res.accessToken, expiration);
          addCookie('s-userId', res.user._id, expiration);
          // Send them back to the page they tried to visit when they were
          // redirected to the login page. Use { replace: true } so we don't create
          // another entry in the history stack for the login page.  This means that
          // when they get to the protected page and click the back button, they
          // won't end up back on the login page, which is also really nice for the
          // user experience.
          navigate('/', { replace: true });
        })
        .catch((err: any) => {
          console.log('ERR: ', err);
          setErrorMessage(err.message);
        })
        .finally(() => setIsLoading(false));
    }
  }

  const sendPasswordResetEmail = () => {
    setIsLoading(true);
    let email = username.toLocaleLowerCase();
    if(!email.endsWith('.staff')){
      email += '.staff';
    }
    client?.service('authManagement').create({
      action: 'sendResetPwd',
      value: { email },
      notifierOptions: {
        staffResetPass: true
      }
    }).then(() => closePasswordDialog());
    //TODO: handle error case
  }

  const closePasswordDialog = () => {
    setIsLoading(false);
    setForgotPasswordOpen(false);
  }


  return (
    <Container component='main' maxWidth='xs'>
      <img src={logoImage} style={{ maxHeight: '175px', maxWidth: '90%', margin: '20px' }}/>
      <Typography variant='h5' style={{ fontStyle: 'italic', color: '#14324a' }}>Staff Login</Typography>
      <Box sx={{ mt: 1 }}>
        <TextField
          fullWidth
          margin="normal"
          variant='outlined'
          label={addAsterickOnError(usernameError, 'Email')}
          error={usernameError}
          value={username}
          onChange={(e) => setUsername(e.currentTarget.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          variant='outlined'
          label={addAsterickOnError(passwordError, 'Password')}
          error={passwordError}
          value={password}
          type='password'
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        <Typography style={{ textDecoration: 'underline', color: 'blue' }} onClick={() => setForgotPasswordOpen(true)}>Forgot Password?</Typography>
        <Typography color={'error'}>{errorMessage}</Typography>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          sx={{ mt: 3, mb: 2 }}
        >
          {isLoading
            ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
            : 'Sign In'
          }
          
        </Button>
      </Box>

      <Dialog open={forgotPasswordOpen}>
        <DialogTitle>Password Reset</DialogTitle>
        <DialogContent>
          <Typography style={{ marginBottom: '10px' }}>
            An email will be sent to you to reset your password.
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            variant='outlined'
            label={addAsterickOnError(usernameError, 'Email')}
            error={usernameError}
            value={username}
            onChange={(e) => setUsername(e.currentTarget.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closePasswordDialog} color="primary">Cancel</Button>
          <Button variant="contained" onClick={sendPasswordResetEmail} disabled={isLoading}>
            {isLoading
              ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
              : 'Submit'
            }
          </Button>
        </DialogActions>
      </Dialog>
      
    </Container>
  );
}
