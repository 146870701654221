import * as React from 'react';
import { 
  Button, Autocomplete, TextField, Grid, Typography, Dialog, DialogTitle, DialogActions, DialogContent, CircularProgress
} from '@mui/material';
import client, { socket } from '../../client';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { IItem } from '../../Interfaces/Database/Item';
import { IPurchase } from '../../Interfaces/Database/Purchase';
import DateTimePicker from 'react-datetime-picker';
import { IAuction } from '../../Interfaces/Database/Auction';

interface IItemMap {
  [itemID: string]: IItem
}
interface IProps {
  auction: IAuction,
  isMobile: boolean
}

export default function NextItem({ auction, isMobile }: IProps) {
  const user = useAppSelector((state: RootState) => state.root.user);
  const authToken = useAppSelector((state: RootState) => state.root.authToken);
  const [itemSelected, setItemSelected] = React.useState<IItem | null>(null);
  const [unsoldItems, setUnsoldItems] = React.useState<IItemMap>({});
  const [pauseAuctionDialogOpen, setPauseAuctionDialogOpen] = React.useState<boolean>(false);
  const [closeAuctionDialogOpen, setCloseAuctionDialogOpen] = React.useState<boolean>(false);
  const [pauseUntilDate, setPauseUntilDate] = React.useState<Date | undefined>(auction.pausedUntil ? new Date(auction.pausedUntil) : undefined);
  const [patchError, setPatchError] = React.useState<string>('');
  const [patchIsLoading, setPatchIsLoading] = React.useState<boolean>(false);
  const [isLoadingSetCurrentItem, setIsLoadingSetCurrentItem] = React.useState<boolean>(false);

  const futureAuction = React.useMemo(() => auction.state < 2, [auction]);


  React.useEffect(() => {
    socket
    .on('purchases created', (purchase: IPurchase) => {
      if(purchase.auctionID === auction._id){
        setUnsoldItems(prevState => (
          Object.values(prevState).reduce((acc, item) => (
            item._id === purchase.itemID
              ? acc
              : { ...acc, [item._id]: item }
          ), {})
          ))
      }
    });

    client?.service('items').find({
      headers: { Authorization: authToken },
      user: user,
      query: {
        auctionID: auction._id,
        isSold: false,
        $select: [ 'itemNumber' ],
        $limit: 0
      },
    })
    .then(async (res: any) => {
      const totalItems = res.total;
      const numberOfQueries = Math.ceil(totalItems / 50);
      const promises = [];
      let allItems: IItemMap = {};
      for(let i = 0; i < numberOfQueries; i++){
        promises.push(client?.service('items').find({
          headers: { Authorization: authToken },
          user: user,
          query: {
            $limit: 50,
            $skip: i * 50,
            auctionID: auction?._id,
            isSold: false,
            $select: [ 'itemNumber' ],
            $sort: { itemNumber: 1 },
          }
        })
          .then((res: any) => res.data)
          .then((items: IItem[]) => {
            items.forEach((item: IItem) => {
              allItems[item._id] = item;
            })
          }));
      }
      await Promise.all(promises);
      setUnsoldItems(allItems);
    });
  }, [ auction._id ]);


  const setAsCurrent = () => {
    if(itemSelected){
      setIsLoadingSetCurrentItem(true);
      client?.service('auctions').patch(auction._id, { currentLiveItem: itemSelected._id }, {
        headers: { Authorization: authToken },
        user
      })
        .then(() => setItemSelected(null))
        .catch((error: string) => {
          console.log('error: ', error);
          //TODO: display error message
        })
        .finally(() => setIsLoadingSetCurrentItem(false));
    }
  };

  const pauseAuction = () => {
    if(pauseUntilDate && pauseUntilDate.getTime() > Date.now()){
      setPatchIsLoading(true);
      setPatchError('');
      client?.service('auctions').patch(auction._id, { 
        pausedUntil: pauseUntilDate,
        currentLiveItem: null
      }, {
        headers: { Authorization: authToken },
        user
      })
        .then(() => setPauseAuctionDialogOpen(false))
        .catch((error: string) => {
          console.log('error: ', error);
          setPatchError('* An error occurred');
        })
        .finally(() => setPatchIsLoading(false))
    } else {
      setPatchError('* Not a Valid Date');
    }
  };

  const closeAuction = () => {
    setPatchIsLoading(true);
    setPatchError('');
    client?.service('auctions').patch(auction._id, {
      _isClosingLive: true
    }, {
      headers: { Authorization: authToken },
      user
    })
      .then(() => setCloseAuctionDialogOpen(false))
      .catch((error: string) => {
        console.log('error: ', error);
        setPatchError('* An error occurred');
      })
      .finally(() => setPatchIsLoading(false))
    
  };



  return (
    <>
      <Dialog open={pauseAuctionDialogOpen}>
        <DialogTitle>Pause Auction</DialogTitle>
        <DialogContent style={{height: '400px'}}>
          <Typography style={{ marginBottom: '10px' }}>Specify the date and time that the auction will resume: </Typography>
          <DateTimePicker onChange={setPauseUntilDate} value={pauseUntilDate}/>
        </DialogContent>
        <DialogActions>
          <Typography color='error' style={{ marginRight: '20px' }}>{patchError}</Typography>
          <Button onClick={() => setPauseAuctionDialogOpen(false)} color="primary">Cancel</Button>
          <Button
            variant="contained"
            onClick={pauseAuction}
            disabled={patchIsLoading}
          >
            {patchIsLoading
              ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
              : 'Save'
            }
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={closeAuctionDialogOpen}>
        <DialogTitle>Close Auction</DialogTitle>
        <DialogContent>
          <Typography color='red' style={{ margin: '10px 0' }}>* Warning: This action is irreversible *</Typography>
          <Typography style={{ margin: '10px 0' }}>All previous item sales are final. Upon closing the auction, 
            bidders will receive emails regarding their purchases made during the live auction.</Typography>
            <Typography style={{ margin: '10px 0', fontStyle: 'italic' }}>Caution: The auction and live stream video will no longer be available to online users.
             Wait to close the auction until everything is final.</Typography>
          {Object.values(unsoldItems).length > 0 &&
            <>
             <Typography color='red' style={{ margin: '10px 0' }}>The following items will be marked as not sold:</Typography>
              {Object.values(unsoldItems).map((item) => (
                  <Typography color='red'>#{item.itemNumber}</Typography>
              ))}
            </>
          }
        </DialogContent>
        <DialogActions>
          <Typography color='error' style={{ marginRight: '20px' }}>{patchError}</Typography>
          <Button onClick={() => setCloseAuctionDialogOpen(false)} color="primary">Cancel</Button>
          <Button
            variant="contained"
            onClick={closeAuction}
            disabled={patchIsLoading}
          >
            {patchIsLoading
              ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
              : 'Save'
            }
          </Button>
        </DialogActions>
      </Dialog>
      
      <Grid container direction={'row'} style={{ padding: '10px' }}>
        <Typography variant='h5' style={isMobile ? {} : { margin: 'auto 20px' }} >Next: </Typography>
        <Autocomplete
          disabled={futureAuction}
          disablePortal
          options={Object.values(unsoldItems)}
          getOptionLabel={(option) => (option ? option.itemNumber.toString() : "")}
          sx={isMobile ? { width: 300, marginBottom: '10px', marginTop: '10px' } : { width: 300 }}
          renderInput={(params) => <TextField {...params} label="Item Number" />}
          value={itemSelected}
          onChange={(e: any, value: IItem | null) => {
            setItemSelected(value);
          }}
        />
        <Button
          variant="contained"
          onClick={setAsCurrent}
          disabled={isLoadingSetCurrentItem || !itemSelected}
          style={isMobile ? { width: '100%', marginBottom: '10px' } : { width: '200px' }}
        >
          {isLoadingSetCurrentItem
            ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
            : 'Set Current Item'
          }
        </Button>
        <Button 
          disabled={futureAuction}
          variant='contained'
          color='warning'
          style={isMobile ? { width: '100%', marginBottom: '10px' } : { marginLeft: '20px' }}
          onClick={() => setPauseAuctionDialogOpen(true)}>
            Pause Auction
        </Button>
        <Button
          disabled={futureAuction}
          variant='contained'
          color='error'
          style={isMobile ? { width: '100%', marginBottom: '10px' } : { marginLeft: '20px' }}
          onClick={() => setCloseAuctionDialogOpen(true)}>
            Close Auction
        </Button>
      </Grid>
    </>
  );
}
