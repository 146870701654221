import React, { useState }  from 'react';
import { 
  Grid, Box, Button
} from '@mui/material';
//@ts-ignore
import Compress from 'client-compress';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


interface IProps {
  itemImages: string[] | undefined,
  altName: string | undefined,
  isUpdate: boolean,
  onChange: () => void,
  sendImages: (images: string[]) => void,
}

interface Main {
  image: string,
  index: number,
}

export default function ItemImages({itemImages, altName, isUpdate, onChange, sendImages}: IProps){ 
  const [imageView, setImageView] = React.useState<string[] | undefined>(itemImages);
  const [mainImage, setMainImage] = React.useState<Main>(); 
  const [isMainSet, setIsMainSet] = React.useState<boolean>(false);
  const [hover, setHover] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(!isMainSet && imageView && imageView.length > 0){
      setMainImage({
        image: imageView[0],
        index: 0,
      });
      setIsMainSet(true);
    }
  });

  // for parent component to have the current item images when user clicks save
  React.useEffect(() => {
    //@ts-ignore
    sendImages(imageView);
  }, [ imageView ]);

  const handleImageChange = (e: any) => {
    e.preventDefault();
    onChange();

    const imageTotal = imageView ? e.target.files.length + imageView.length : e.target.files.length;

    if(imageTotal > 5){
      alert('An item can only have a maximum of 5 images');
    }
    else{
      const newImages: string[] = [];
      const promises: any[] = [];

      const compressOptions = {
        targetSize: 0.2,
        quality: 0.75,
        maxWidth: 800,
        maxHeight: 600,
      };

      const compress = new Compress(compressOptions);
      compress.compress([ ...e.target.files ])
        .then((conversions: any) => {

          for(let i = 0; i < conversions.length; i++){
            const promise = new Promise((res) => {

              Compress.blobToBase64(conversions[i].photo.data)
                .then((base64: string) => {
                  newImages.push(base64.split('base64,')[1]);
                  res(true);
                });
            });
            promises.push(promise);
          }
          Promise.all(promises)
          .then(res => {
            if(imageView) setImageView([...imageView, ...newImages]);
            else setImageView(newImages);
          });
        })
        .catch((err: any) => {
          console.log('err: ', err);
        });
    }
  };

  const removeImage = () => {
    if(mainImage){
      onChange();
      //@ts-ignore
      setImageView(imageView.filter(el => el !== mainImage.image));
      setIsMainSet(false);
    }
  };


  const mainRef = React.createRef<HTMLDivElement>();
  const [dimensions, setDimensions] = useState({ height: 0 });


  //TODO: I have it set to updating whenever the offsetWidth changes - Why does it never update?
  //      also, the height does not equal the width and idk why
  React.useEffect(() => {
    if (mainRef.current) {
      setDimensions({
        height: mainRef.current.offsetWidth,
      });
    }
  }, [ mainRef.current?.offsetWidth ]); //mainRef.current?.offsetWidth

  return (
    <>
      {/* Upload / Delete Item Images */}
      {isUpdate && 
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '80%', margin: 'auto' }}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="raised-button-file">
            <Button component="span" variant="outlined">
              <AddCircleIcon/>&nbsp;Add Images
            </Button>
          </label> 

          <Button variant="outlined" onClick={removeImage}>
            <RemoveCircleIcon/>&nbsp;Remove Image
          </Button>
        </Box>
      }

      <Box> 
        <div ref={mainRef} style={imageView && imageView.length > 0 ? {
          width: '80%',
          border: '1px black solid',
          margin: 'auto',
          backgroundColor: '#F4F4F4',
          height: dimensions.height * .8
        } : {
          width: '80%',
          border: '2px red solid',
          margin: 'auto',
          backgroundColor: '#F4F4F4',
          height: dimensions.height * .8
        } }>
          {isMainSet && mainImage &&
            <img 
              src={`data:image/png;base64,${mainImage.image}`} 
              alt={altName} 
              style={{width: '100%', height: '100%', objectFit: 'contain'}}
            />
          }
        </div>
      </Box>

      <Grid container style={{ margin: '5px', alignItems: 'center', justifyContent: 'center' }}>
        {imageView && imageView.length > 1 &&
          imageView.map((el, index) => {
            return(
              <Grid
                key={index}
                style={mainImage?.index == index ? {
                  margin: '3px',
                  border: '3px #000 solid',
                  } : { 
                  margin: '3px',
                  border: '1px black solid',
                }}
                onClick={() => {
                  setMainImage({ image: el, index: index });
                }}
              >
                <img src={`data:image/png;base64,${el}`} alt={altName} style={{  width: '50px',  height: '50px', objectFit: 'cover' }}/> 
              </Grid>
            );
          })
        }
      </Grid>
    </>
  );
}
