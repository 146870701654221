import { CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import { IUserCheckedInMap } from './search';
import SearchResults from './search-results';
import client from '../../client';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { ICheckIn } from '../../Interfaces/Database/CheckIn';

interface IProps {
  auctionID: string | undefined
}

export default function CheckInList({ auctionID }: IProps) {
  const user = useAppSelector((state: RootState) => state.root.user);
  const authToken = useAppSelector((state: RootState) => state.root.authToken);

  const [checkedIn, setCheckedIn] = React.useState<IUserCheckedInMap>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  
  React.useEffect(() => {
    setIsLoading(true);
    client?.service('check-in').find({
      headers: { Authorization: authToken },
      user: user,
      query: {
        auctionID,
        $limit: 0
      }
    })
      .then(async (res: any) => {
        const totalUsers = res.total;
        const numberOfQueries = Math.ceil(totalUsers / 50);
        const promises = [];
        const usersCheckedInMap: IUserCheckedInMap = {};
        for(let i = 0; i < numberOfQueries; i++){
          promises.push(client?.service('check-in').find({
            headers: { Authorization: authToken },
            user: user,
            query: {
              auctionID,
              $limit: res.total,
              $skip: i * 50,
              $sort: { bidderNumber: 1 }
            },
          })
            .then((res: any) => {
              res.data.forEach((user: ICheckIn) => {
                usersCheckedInMap[user.userID] = {
                  ...user,
                  checkedIn: true
                } as any;
              })
            }));
        }
        await Promise.all(promises);
        setCheckedIn(usersCheckedInMap);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    isLoading
      ? <Grid container style={{ marginTop: '30px', display: 'flex', height: '100%' }}>
          <CircularProgress style={{ margin: 'auto' }} />
        </Grid>
      :
        <>
          <Typography style={{ margin: '8px' }}>Total: {Object.keys(checkedIn).length}</Typography>
          <SearchResults
            users={checkedIn}
            auctionID={auctionID}
            onCreate={(userID: string) => { console.log('should not be here') }}
          />
        </>
    
  );
}