import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";


export function RequireAuth({ children }: { children: JSX.Element }) {
  const user = useAppSelector((state: RootState) => state.root.user);
  let location = useLocation();
  const authenticatedPath = location.pathname !== '/setup' && location.pathname !== '/password-reset' && location.pathname !== '/login';

  if (!user && authenticatedPath) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if(!user && !authenticatedPath){
    // stay on current page and no not render authenticated components
    return <></>;
  } else {
    return children;
  }
}