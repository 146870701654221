import { useParams } from 'react-router-dom';
import { Container, Paper } from '@mui/material';
import NextItem from './next-item';
import { IItem } from '../../Interfaces/Database/Item';
import CurrentItem from './current-item';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import React from 'react';
import { IAuction } from '../../Interfaces/Database/Auction';
import client, { socket } from '../../client';

export interface IItemSearch {
  label: string,
  item: IItem
}

export default function LiveAuction() {
  let { auctionID } = useParams();
  const user = useAppSelector((state: RootState) => state.root.user);
  const authToken = useAppSelector((state: RootState) => state.root.authToken);
  const [auction, setAuction] = React.useState<IAuction>();

  const [width, setWidth] = React.useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  React.useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;

  React.useEffect(() => {
    socket
    .on('auctions patched', (patchedAuction: IAuction) => {
      if(patchedAuction._id === auctionID){
        setAuction(patchedAuction);
      }
    });

    client?.service('auctions').get(auctionID, {
      headers: { Authorization: authToken },
      user: user
    }).then((res: IAuction) => {
      setAuction(res);
    });

  }, [ auctionID ]);

  return (

    <Container>
      {auction &&
        <>
        <Paper style={isMobile ? {} : { height: '70vh', overflowY: 'scroll' }}  elevation={4}>
          <CurrentItem auction={auction} isMobile={isMobile}/>
        </Paper>

        {auction.state < 3 && // not closed
          <Paper sx={isMobile ? { backgroundColor: '#E5F3FF'} : { position: 'sticky', bottom: 0, backgroundColor: '#E5F3FF'}} elevation={4}>
            <NextItem auction={auction} isMobile={isMobile}/>
          </Paper>
        }
        </>
      }
    </Container>
  );
}