export default {

  // LOCAL
  // SERVER_URL: 'http://localhost:3030',
  // SQUARE_SCRIPT: 'https://sandbox.web.squarecdn.com/v1/square.js',
  // SQUARE_APP_ID: 'sandbox-sq0idb-qUn5noNNMYagX8TV5EDIWQ',
  // SQUARE_LOCATION_ID: 'LHM9EJX0RZ486',

  // PROD
  SERVER_URL: 'https://auction-prod.herokuapp.com/',
  SQUARE_SCRIPT: 'https://web.squarecdn.com/v1/square.js',
  SQUARE_APP_ID: 'sq0idp-Crj7OCnBarJCSWk2gtk-zA',
  SQUARE_LOCATION_ID: 'L40EJTTW1018Y',
};