import * as React from 'react';
import { Typography,
  List, ListItem, ListItemButton,
  ListItemText, ListSubheader, CircularProgress, Popover
} from '@mui/material';
import { IItem } from '../../Interfaces/Database/Item';
import { IBid } from '../../Interfaces/Database/Bid';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import client, { socket } from '../../client';
import GavelIcon from '@mui/icons-material/Gavel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IUser } from '../../Interfaces/Database/User';

interface IProps {
  itemID: string | undefined,
  currentItem: IItem | null,
  isMobile: boolean,
  onRowClick: (bid: IBid) => void,
}

interface IBidMap {
  [bidID: string]: IBid
}

export default function BidsList({ itemID, currentItem, isMobile, onRowClick }: IProps) {
  const user = useAppSelector((state: RootState) => state.root.user);
  const authToken = useAppSelector((state: RootState) => state.root.authToken);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [viewName, setViewName] = React.useState<boolean>(false);
  const [bidderNameView, setBiddNameView] = React.useState<string>('Hello');
  const [isLoadingName, setIsLoadingName] = React.useState<boolean>(false);

  const [bids, setBids] = React.useState<IBidMap>({});
  const [skippedBids, setSkippedBids] = React.useState<number>(0);
  const [getMoreBidsLoading, setGetMoreBidsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    scrollToBottom();
  }, [ bids ]);

  React.useEffect(() => {
    getBids();
    if(itemID){
      getBids();
      socket
      .on('bids created', (bid: IBid) => {
        if(bid.itemID === itemID){
          setBids(prevState => ({...prevState, [bid._id]: bid}));
        }
      })
    }
  },[itemID]);

  const getBids = async () => {
    if(itemID && user){
      client?.service('bids').find({
        headers: { Authorization: authToken },
        user: user,
        query: {
          itemID,
          $limit: 0
        },
      })
      .then((res: any) => {
        let skip = 0;
        if(res.total > 25){
          skip = res.total - 25;
          setSkippedBids(skip);
        }
        return client?.service('bids').find({
          headers: { Authorization: authToken },
          user: user,
          query: {
            itemID,
            $limit: res.total,
            $skip: skip,
            $sort: { createdAt: 1 }
          },
        })
      })
      .then((res: any) => res.data)
      .then((bids: IBid[]) => {
        setBids(bids.reduce((acc, bid) => (
          { ...acc, [bid._id]: bid }
        ), {}));
      })
    }
  }

  const getMoreBids = () => {
    setGetMoreBidsLoading(true);
    const pageSize = 10;
    let skip = 0;
    let limit = skippedBids;
    if(skippedBids > pageSize){
      skip = skippedBids - pageSize;
      limit = pageSize;
    }
    client?.service('bids').find({
      headers: { Authorization: authToken },
      user: user,
      query: {
        itemID,
        $skip: skip,
        $limit: limit,
        $sort: { createdAt: 1 }
      },
    })
      .then((res: any) => res.data)
      .then((bids: IBid[]) => {
        setBids(prevState => ({
          ...bids.reduce((acc, bid) => (
            { ...acc, [bid._id]: bid }
          ), {}),
          ...prevState
        }) );
        setSkippedBids(skip);
      })
      .finally(() => setGetMoreBidsLoading(false));
  }

  const displayBidderName = (event: any, bidderNumber: number) => {
    setAnchorEl(event.currentTarget);
    setViewName(true);
    setIsLoadingName(true);
    client?.service('users').find({
      headers: { Authorization: authToken },
      user: user,
      query: {
        bidderNumber,
        $limit: 1
      }
    })
      .then((res: any) => res.data)
      .then((users: IUser[]) => {
        if(users.length === 1){
          setBiddNameView(`${users[0].bidderNumber}: ${users[0].firstName} ${users[0].lastName}`);
        }
      })
      .catch(() => setBiddNameView('error retrieving name'))
      .finally(() => setIsLoadingName(false));
  };

 const bidScrollRef = React.createRef<HTMLInputElement>();

 const scrollToBottom = () => {
   bidScrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
 }

  return (
    <div ref={bidScrollRef} style={
      isMobile 
      ? { height: '300px', width: '95%', overflowY: 'scroll', display: 'flex', flexDirection: 'column-reverse' }
      : { height: '80%', width: '95%', overflowY: 'scroll', display: 'flex', flexDirection: 'column-reverse'  }
    }>
      <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <ListSubheader style={{ backgroundColor: '#F6F6F6' }}>
          <ListItemButton>
            <ListItemText 
              sx={{ width: '30px' }}
              key={`bidder-number-header`} 
              primary={'Bidder'}
            />
            <ListItemText
              sx={{ width: '40px' }}
              key={`bid-price-header`} 
              primary={`Price`} 
            />
            <ListItemText
              sx={{ width: '40%' }}
              key={`date-time-header`} 
              primary={`Timestamp`} 
            />
          </ListItemButton>
        </ListSubheader>
        {skippedBids > 0 &&
          <ListItem
            key={'load-all-bids'}
            style={{ backgroundColor: '#FDFFD9' }}
            disablePadding
            onClick={getMoreBids}
            disabled={getMoreBidsLoading}
          >
            <ListItemButton>
              <ListItemText 
                sx={{ textAlign: 'center' }}
                key={'load-all-bids-text'} 
                primary={
                  getMoreBidsLoading
                    ? <CircularProgress size={'1.5rem'}/>
                    : <>
                        <Typography display='inline' style={{verticalAlign: 'middle', marginRight: '10px'}}>Load more</Typography> 
                        <ArrowUpwardIcon display='inline' style={{verticalAlign: 'middle'}}/>
                      </>
                }
              />
            </ListItemButton>
          </ListItem>
        }
        {Object.values(bids)
        .sort((a, b) => new Date((a as any).createdAt).getTime() - new Date((b as any).createdAt).getTime())
        .map((bid: IBid) => (
          <ListItem
            key={bid._id}
            style={currentItem?.highestBidID === bid._id ? { backgroundColor: '#FDFF7A' } : {}}
            disablePadding
            secondaryAction={
              currentItem?.isSold
                ? <></>
                : <GavelIcon 
                  color={currentItem?.highestBidID === bid._id ? 'error' : 'disabled'} 
                  onClick={() => onRowClick(bid)}
                  style={{ cursor: 'pointer' }}
                />
            }
          >
            <ListItemButton>
              <ListItemText 
                sx={{ width: '30px' }}
                key={`bidder-number-${bid._id}`} 
                primary={`# ${bid.bidderNumber}`}
                onClick={(e) => displayBidderName(e, bid.bidderNumber)}
              />
              <ListItemText
                sx={{ width: '40px' }}
                key={`bid-price-${bid._id}`} 
                primary={`$ ${bid.price}`} 
              />
              <ListItemText
                sx={{ width: '40%' }}
                style={bid.isCreatedLive ? { color: 'black' } : { color: 'red'}}
                key={`date-time-${bid._id}`} 
                primary={`${new Date((bid as any).initalTimeSet).toLocaleString()} ${!bid.isCreatedLive ? '(online)' : ''}`} 
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Popover
        open={viewName}
        anchorEl={anchorEl}
        onClose={() => {
          setViewName(false);
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {isLoadingName
          ? <CircularProgress size='2rem' style={{ margin: 'auto', padding: '5px' }} />
          : <Typography style={{ margin: 'auto', padding: '5px' }}>{bidderNameView}</Typography>
        }
      </Popover>
    </div>
  );
}
