import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import client from '../../client';
import { IStaffRole } from '../../Interfaces/Database/StaffRole';
import { IAuction } from '../../Interfaces/Database/Auction';
import { useParams } from 'react-router-dom';
import ItemsTable from './items-table';
import React from 'react';

export interface IAuctionTab {
  role: IStaffRole,
  auction: IAuction
}

export default function ItemsList() {
  let { auctionID } = useParams();
  const user = useAppSelector((state: RootState) => state.root.user);
  const authToken = useAppSelector((state: RootState) => state.root.authToken);

  const [auction, setAuction] = React.useState<IAuction | null>(null);

  React.useEffect(() => {
    client?.service('auctions').get(auctionID, {
      headers: { Authorization: authToken },
      user: user
    }).then((res: any) => setAuction(res));
  }, [auctionID])


  return (
    <ItemsTable auction={auction}/>
  );
}