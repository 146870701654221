import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import {
  ExpandLess,
  ExpandMore,
  Gavel as GavelIcon
} from '@mui/icons-material';

import { Link } from 'react-router-dom';
import dashify from 'dashify';
import { IAuctionTab } from './tabs-list';
import { constants } from '../../config/constants'
import { useLocation } from 'react-router-dom';

interface IProps {
  tab: IAuctionTab
}

export default function NestedTabsList({tab}: IProps) {
  let location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [viewableTabs, setViewableTabs] = React.useState<string[]>([]);

  React.useEffect(() => {
    if(tab){
      const roles = tab.roles.map(role => role.role);
      let tabs = constants.tabs.general;
      if(roles.includes('admin')){
        tabs = tabs.concat(constants.tabs.admin);
      }
      if(roles.includes('clerk')){
        tabs = tabs.concat(constants.tabs.clerk);
      }
      setViewableTabs(tabs);
    }
    
  }, [ tab ])

  
  return (
  <>
    <ListItemButton onClick={() => setOpen(!open)}>
      <ListItemIcon>
        <GavelIcon />
      </ListItemIcon>
      <ListItemText primary={tab.auction.name} secondary={new Date(tab.auction.startDate).toLocaleDateString()}/>
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {viewableTabs.map((nestedTab: string) => {
          const path = `/${tab.auction._id}/${dashify(nestedTab)}`;
          return (
            <Link key={path} to={path} style={{ textDecoration: 'none', color: 'black' }}>
              <ListItemButton sx={{ pl: 9 }} style={{ backgroundColor: location.pathname === path ? '#E0E0DF' : 'white' }}>
                <ListItemText primary={nestedTab} />
              </ListItemButton>
            </Link>
          );
        })}
      </List>
    </Collapse>
  </>
  );
     
}