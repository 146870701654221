import { Button, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import client from '../../client';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import config from '../../config/global';
import { v4 as uuid } from 'uuid';
import { ICard } from './card-info-dialog';
import './square.css';
import { IUserCheckedIn } from './search';


interface IProps {
  currentCard: ICard | null | undefined,
  customer: IUserCheckedIn,
  setCurrentCard: (card: ICard) => void
}

export default function AddCard({ currentCard, customer, setCurrentCard }: IProps) {
  const user = useAppSelector((state: RootState) => (state.root as any).user);
  const authToken = useAppSelector((state: RootState) => (state.root as any).authToken);
  const square = useAppSelector((state: RootState) => (state.root as any).square);
  const appId = config.SQUARE_APP_ID;
  const locationId = config.SQUARE_LOCATION_ID;

  const [cardContainerSet, setCardContainerSet] = React.useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(square && currentCard !== undefined && !cardContainerSet){
      // Entry point of the Web Payments SDK
      const payments = square.payments(appId, locationId);
      let card: any;
      initializeCard(payments)
        .then(res => {
          card = res;
          setCardContainerSet(true);
        })
        .catch(err => {
          console.error('Initializing Card failed', err);
          return;
        });
    
        // Step 5.2: create card payment
        document.getElementById('card-button')?.addEventListener('click', function (event: any) {
          handleCardSubmit(event, card);
        });

    } else {
      console.log('Square.js failed to load properly');
    }

  }, [square, currentCard]);

  async function initializeCard(payments: any) {
    const card = await payments.card();
    await card.attach('#card-container'); 
    return card; 
  }

  function displayStatus(status: string) {
    const statusContainer: any = document.getElementById('payment-status-container');
    if (status === 'SUCCESS') {
      statusContainer.innerHTML = '';
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
      statusContainer.innerHTML = status;
    }
    statusContainer.style.visibility = 'visible';
  }  


  async function handleCardSubmit(event: any, paymentMethod: any) {
    event.preventDefault();
    setSubmitLoading(true);

    const tokenResult = await paymentMethod.tokenize()
      .catch(() => setSubmitLoading(false));
    if (tokenResult.status === 'OK') {
      client?.service('square-cards').create({
        idempotencyKey: uuid(),
        locationId,
        sourceId: tokenResult.token, // card nonce
        card: {
          customerId: customer?.customerID,
        },
      }, { 
        user,
        Authentication: authToken,
      })
        .then((res: any) => {
          displayStatus('SUCCESS');
          setCurrentCard(res);
          setSubmitLoading(false);
        })
        .catch((err: any) => {
          console.log('err: ', err);
          displayStatus(err.message);
          setSubmitLoading(false);
        });
    } else {
      setSubmitLoading(false);
      displayStatus(JSON.stringify(tokenResult.status));
      console.log(tokenResult);
    }
  }  


  return (
    <div style={{ marginTop: '20px' }}>
      <form id="payment-form">
        {currentCard 
          ?
            <>
              <Typography style={{ fontWeight: 'bold' }}>Update Card:</Typography>
              <Typography>
                Customers may only have one card on file. Upon updating, the current card will be replaced.
              </Typography>
            </>
          : <Typography style={{ fontWeight: 'bold' }}>Add Card on File:</Typography>
        }
        <div id="card-container"></div>
        <Button id='card-button' disabled={submitLoading}>
          {submitLoading 
            ? <div style={{ width: '100%', textAlign: 'center'}}>
                <CircularProgress size='1rem' style={{ margin: 'auto' }} />
              </div>
            : <Typography>{currentCard ? 'update card' : 'add card'}</Typography>
          }   
        </Button>
      </form>
      <div id="payment-status-container"></div>
    </div>
  );
}