import { useParams } from 'react-router-dom';
import { Autocomplete, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TextField, Typography } from '@mui/material';
import { IItem } from '../../Interfaces/Database/Item';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import React from 'react';
import { IAuction } from '../../Interfaces/Database/Auction';
import client from '../../client';
import { IUser } from '../../Interfaces/Database/User';
import { IPurchase } from '../../Interfaces/Database/Purchase';
import PaymentDetails from './paymentDetails';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

export interface IItemSearch {
  label: string,
  item: IItem
}

export interface ItemWithPurchase {
  item: IItem,
  purchase: IPurchase,
}

interface DeliveryBundle {
  type: string,
  address: string | null,
  items: ItemWithPurchase[],
  shippingCost: number | null,
}

export interface PurchaseBundle {
  purchaseBundles: DeliveryBundle[],
  serviceCost: number | null,
  totalCost: number,
  paidTimeStamp: Date | null
}


export interface PendingPurchaseBundle {
  purchases: ItemWithPurchase[],
  serviceCost: number | null,
  totalCost: number,
}

interface AuctionBundle {
  auction: IAuction,
  data: PurchaseBundle,
}

export default function Invoices() {
  let { auctionID } = useParams();
  const user = useAppSelector((state: RootState) => state.root.user as any);
  const authToken = useAppSelector((state: RootState) => state.root.authToken as any);

  const [auction, setAuction] = React.useState<IAuction>();
  const [bidderNumberSearch, setbidderNumberSearch] = React.useState<string>();
  const [purchasesUserDisplayed, setPurchasesUserDisplayed] = React.useState<IUser | null | undefined>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [purchases, setPurchases] = React.useState<AuctionBundle[] | undefined>(undefined);

  const [confirmPayment, setConfirmPayment] = React.useState<PurchaseBundle | undefined>();
  const [processPaymentError, setProcessPaymentError] = React.useState<string>();
  const [isLoadingPayment, setIsLoadingPayment] = React.useState<boolean>(false);

  const componentToPrintRef = React.createRef<HTMLInputElement>();


  React.useEffect(() => {
    client?.service('auctions').get(auctionID, {
      headers: { Authorization: authToken },
      user: user
    }).then((res: any) => setAuction(res));
  }, []);

  const getReport = async () => {
    if(bidderNumberSearch){
      setIsLoading(true);
      const userSearched = await client?.service('users').find({
        headers: { Authorization: authToken },
        user: user,
        query: {
          bidderNumber: parseInt(bidderNumberSearch),
          isStaff: false,
          $limit: 1
        }
      }) 
        .then((res: any) => res.data)
        .then((users: IUser[]) => users.length === 1 ? users[0] : null);
  
      if(userSearched){
        client?.service('purchases-report').get(userSearched._id, {
          headers: { Authorization: authToken },
          user: user,
          query: {
            auctionID
          }
        })
          .then((res: AuctionBundle[]) => {
            setPurchases(res);
          })
          .catch((err: any) => console.log('err: ', err))
          .finally(() => {
            setPurchasesUserDisplayed(userSearched);
            setIsLoading(false);
          });
      } else {
        setPurchasesUserDisplayed(undefined);
        setIsLoading(false);
        setPurchases(undefined);
      }
    }
  };

  const initiatePayment = async () => {
    const promises: any = [];
    setIsLoadingPayment(true);
    setProcessPaymentError('');
    const purchasesToUpdate = confirmPayment?.purchaseBundles[0].items || [];
    // handle bulk purchases except one - then the last one by itself to only initiate payment once
    if(purchasesToUpdate.length > 1){
      purchasesToUpdate.slice(1).map(el => 
        promises.push(client?.service('purchases').patch(el.purchase._id, {
          deliveryType: 1
        }, {
          headers: {
            Authorization: authToken,
          },
          user: user || undefined,
        }).then((res: any) => res))
      );
      await Promise.all(promises);
    }

    client?.service('purchases').patch(purchasesToUpdate[0].purchase._id, {
      deliveryType: 1
    }, {
      headers: {
        Authorization: authToken,
      },
      user: user || undefined,
    })
      .then((patchedPurchase: any) => {
        const paymentSuccessful = patchedPurchase.status === 'charged';
        if(!!paymentSuccessful){
          setConfirmPayment(undefined);
          getReport();
        } else {
          setProcessPaymentError('There are still pending purchases. Please refresh the page and try again.');
        }
      })
      .catch((err: any) => setProcessPaymentError('An error has occurred'))
      .finally(() => setIsLoadingPayment(false));
  };

  return (
    <Container>
      <style> 
       {`@media print {
        @page {
          margin:50px;
        } }`}
      </style>

      <Dialog open={!!confirmPayment}>
        <DialogTitle>Payment Confirmation</DialogTitle>
        <DialogContent>
          {auction?.isTesting &&
            <Typography color='error' style={{ marginBottom: '10px', fontStyle: 'italic' }}>
              * This is a test auction. The square transaction will be bypassed.</Typography>
          }
          <Typography style={{ marginBottom: '10px' }}>Upon selecting "Process", the purchaser's card on file will be charged
           ${confirmPayment?.serviceCost 
            ? (confirmPayment?.totalCost + confirmPayment?.serviceCost).toFixed(2) 
            : confirmPayment?.totalCost.toFixed(2)}.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Typography color={'error'}>{processPaymentError}</Typography>
          <Button onClick={() => setConfirmPayment(undefined)} color="primary">Cancel</Button>
          <Button variant="contained" onClick={initiatePayment} disabled={isLoadingPayment}>
            {isLoadingPayment
              ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
              : 'Process'
            }
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ margin: '30px' }}>
        <TextField 
          value={bidderNumberSearch}
          style={{ float: 'left' }}
          id="bidder-number" label={'Bidder Number'} variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e: any) => setbidderNumberSearch(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={getReport}
          disabled={isLoading}
          style={{ width: '200px', height: '55px'}}
        >
          {isLoading
            ? <CircularProgress style={{ color: 'white' }} size={'1.5rem'}/>
            : 'Get Invoice'
          }
        </Button>
      </div>

      <div ref={componentToPrintRef}>
        {purchasesUserDisplayed
          ?
            <div style={{ margin: '30px', border: '1px solid black', borderRadius: '5px', maxWidth: '500px' }}>
              <div style={{ margin: '10px' }}>
                <Typography style={{ fontWeight: 'bold' }}>Royale Treasures Auction</Typography>
                <Typography>Name: {purchasesUserDisplayed.firstName} {purchasesUserDisplayed.lastName}</Typography>
                <Typography>Email: {purchasesUserDisplayed.email}</Typography>
              </div>
            </div>
          : purchasesUserDisplayed === undefined && <Typography color='error' style={{ margin: '30px' }}>* User does not exist</Typography>
        }

        {purchases && purchases.length
          ?
            <TableContainer component={Paper} >
              <ReactToPrint content={() => componentToPrintRef.current}>
                <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <Button style={{ marginLeft: '30px' }} onClick={handlePrint}>Print All Invoices</Button>
                    )}
                  </PrintContextConsumer>
              </ReactToPrint>
              <Table stickyHeader aria-label="sticky table">
                <TableBody>
                  {purchases
                    .map((row, index) => (
                      <PaymentDetails key={index} data={row.data}>
                        {row.data.paidTimeStamp 
                          ?<>
                          <TableCell scope='row' component='th'><CheckCircleIcon color='success'/></TableCell>
                          <TableCell scope='row' component='th'>Paid on {new Date(row.data.paidTimeStamp).toLocaleString()}</TableCell>
                          <TableCell></TableCell>
                          </>:<>
                          <TableCell scope='row' component='th'><FlagCircleIcon color='error'/></TableCell>
                          <TableCell scope='row' component='th'>Pending Purchase</TableCell>
                          <TableCell scope='row' component='th'>
                            <Button variant='contained' color='error' 
                              style={{float: 'right'}} onClick={() => setConfirmPayment(row.data)}
                            >Process Payment</Button>
                          </TableCell>
                          </>
                        }
                      </PaymentDetails>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          : purchases && <Typography color='error' style={{ margin: '30px' }}>* No purchases</Typography>
        }
      </div>


    </Container>
  );
}