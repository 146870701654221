import { useParams } from 'react-router-dom';
import { Box, Container, Tabs, Tab } from '@mui/material';
import React from 'react';
import Search from './search';
import CheckInList from './check-in-list';


export default function CheckIn() {
  let { auctionID } = useParams();
  const [selectedTab, setSelectedTab] = React.useState<number>(0);


  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={(e: any, newValue: number) => setSelectedTab(newValue)}>
          <Tab label="Check in" key={`tab-1`} />
          <Tab label="List" key={`tab-2`} />
        </Tabs>
      </Box>
      {selectedTab === 0 &&
        <Search auctionID={auctionID}/>
      }
      {selectedTab === 1 &&
        <CheckInList auctionID={auctionID}/>
      }
    </Container>
  );
}