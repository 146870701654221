import Login from './components/screens/login';
import Setup from './components/screens/setup';
import './App.css';
import { Typography } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import Home from './components/screens/home';
import { RequireAuth } from './components/auth/require-auth';
import React from 'react';
import { socket } from './client';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { RootState } from './app/store';
import { parseCookie, removeCookie } from './helpers/cookie';
import { setUser, setSquare } from './global-state/root-slice';
import { useLocation, useNavigate } from 'react-router-dom';
import DrawerNavigation from './components/navigation/drawer-navigation';
import config from './config/global';
import ItemsList from './components/items-list';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { IItem } from './Interfaces/Database/Item';
import ItemDetail from './components/items-list/item-detail-wrapper';
import LiveAuction from './components/live-auction';
import Invoices from './components/invoices';
import CheckIn from './components/check-in';
import ResetPassword from './components/screens/reset-password';
import AuctionDetails from './components/auction-details';

function App() {
  const square = useAppSelector((state: RootState) => (state.root as any).square);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let location = useLocation();


  //@ts-ignore
  let from = location.state?.from?.pathname || "/";

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      },
    }
  });


  React.useEffect(() => {
    // Appending Square script
    const sqScript = document.createElement('script');
    sqScript.src = config.SQUARE_SCRIPT;
    sqScript.type = 'text/javascript';
    sqScript.async = false;
    sqScript.onload = () => {
      dispatch(setSquare((window as any).Square));
    };
    document.getElementsByTagName('head')[0].appendChild(sqScript);


    const cookies: any = parseCookie(document.cookie);
    socket?.on('connect', () => {
      console.log('Socket Connected');
      if (cookies['s-accessToken'] && cookies['s-userId']) {
        console.log('attempting login');
        socket
          .emit('create', 'authentication', {
            strategy: 'jwt',
            accessToken: cookies['s-accessToken'],
          }, (err: any, newAuthResult: any) => {
            // Auth Token Authentication failed
            if (err) {
              console.log('Invalid Cookie Data:', err);
              return removeCookie('accessToken');
            }
            dispatch(setUser({ 
              user: newAuthResult.user, 
              authToken: newAuthResult.accessToken 
            }));
            navigate(location.pathname, { replace: true });
          })
          .on('error', (err: any) =>{
            console.log('error', err);
          });
      } else {
        console.log('no cookies');
        //navigate('/login', { replace: true });
      }

      socket
      .on('items patched', (item: IItem) => {
        queryClient.invalidateQueries('test');
      })
    });
  }, [])



  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path='/login' element={<Login/>} />
        <Route path='/setup' element={<Setup/>} />
        <Route path='/password-reset' element={<ResetPassword/>} />
      </Routes>

      <RequireAuth>
        <DrawerNavigation>
          <Routes>
            <Route path={`/:auctionID/items-list`} element={<ItemsList/>}/>
            <Route path={`/:auctionID/items-list/:itemID`} element={<ItemDetail/>}/>
            <Route path={`/:auctionID/items-list/create`} element={<ItemDetail/>}/>
            <Route path={`/:auctionID/live-auction`} element={<LiveAuction/>}/>
            <Route path={`/:auctionID/invoices`} element={<Invoices/>}/>
            <Route path={`/:auctionID/check-in`} element={<CheckIn/>}/>
            <Route path={`/:auctionID/auction-details`} element={<AuctionDetails/>}/>
            <Route path='/' element={<Home/>}/>
            <Route path='*' element={<Typography>Not Found</Typography>} />
          </Routes>
        </DrawerNavigation>
      </RequireAuth>
    </QueryClientProvider>
  );
}

export default App;
