import React from 'react';
import { 
  List, ListItem, ListItemButton,
  ListItemText, ListSubheader
} from '@mui/material';
import { IUserCheckedIn, IUserCheckedInMap } from './search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CardInfoDialog from './card-info-dialog';

interface IProps {
  users: IUserCheckedInMap,
  auctionID: string | undefined,
  onCreate: (userID: string) => void
}

export default function SearchResults({ users, auctionID, onCreate }: IProps) {
 const [currentUser, setCurrentUser] = React.useState<IUserCheckedIn | null>(null);
 const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
 
 const openConfirmationDialog = (userCheckedIn: IUserCheckedIn) => {
  setCurrentUser(userCheckedIn);
  setDialogOpen(true);
 }

  return (
    <div>
      {currentUser &&
        <CardInfoDialog 
          open={dialogOpen} 
          auctionID={auctionID} 
          userToCheckIn={currentUser} 
          onClose={() => setDialogOpen(false)} 
          onCreate={onCreate}
        />
      }
      <List dense sx={{ bgcolor: 'background.paper' }}>
        <ListSubheader style={{ backgroundColor: '#F6F6F6' }}>
          <ListItemButton>
            <ListItemText 
              sx={{ width: '200px' }}
              key={`name-header`} 
              primary={'Name'}
            />
            <ListItemText
              sx={{ width: '50px', textAlign: 'center' }}
              key={`bidder-number-header`} 
              primary={`Bidder Number`} 
            />
            <ListItemText
              sx={{ width: '30px', textAlign: 'center' }}
              key={`phone-number-header`} 
              primary={`Check In`} 
            />
          </ListItemButton>
        </ListSubheader>
        {Object.values(users).map((user: IUserCheckedIn) => (
          <ListItem
            key={user._id}
            disablePadding
          >
            <ListItemButton>
              <ListItemText 
                sx={{ width: '200px' }}
                key={`name-${user._id}`} 
                primary={`${user.firstName} ${user.lastName}`}
                secondary={user.email}
              />
              <ListItemText
                sx={{ width: '40px', textAlign: 'center' }}
                key={`bidder-number-${user._id}`} 
                primary={`# ${user.bidderNumber}`} 
              />
              <ListItemText
                sx={{ width: '30px', textAlign: 'center' }}
                key={`checked-in-${user._id}`} 
                primary={user.checkedIn
                  ? <CheckCircleIcon 
                      color='success' 
                    />
                  : <RadioButtonUncheckedIcon 
                    color='warning'
                    onClick={() => openConfirmationDialog(user)}
                  />} 
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
