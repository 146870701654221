export const SHIPPING_TYPE = ['No Shipping', 'Standard', 'Overnight'];
export type ShippingType = 'No Shipping' | 'Standard' | 'overnight';

export interface IItem {
  // Mongoose Auto-Generated UID
  _id:            string,

  // References
  auctionID:      string,
  categoryID:     string,
  highestBidID:   string,

  // Data
  title:          string,
  category:       string,
  description:    string,
  pictures:       Array<string>,
  isSold:         boolean,
  purchasePrice?: number,
  purchaseBidder?:number,
  startingBid:    number,
  isBuyNow:       boolean,
  buyNowPrice?:   number,
  bidIncrement:   number,
  shippingType:   ShippingType, 
  shippingCost:   number,       //NO SHIPPING -  Pick up only: -1, free shipping: 0
  itemNumber:     number,
  isClosed:       boolean,      // If Shipped or Picked Up
}