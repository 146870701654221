import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import client from '../../client';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ICategory } from '../../Interfaces/Database/Category';
import ItemDetail from './item-detail';


export interface ICategoryMap{
  [key: string]: string,
}

export default function ItemDetailWrapper() {
  let { itemID } = useParams();
  const user = useAppSelector((state: RootState) => state.root.user);
  const authToken = useAppSelector((state: RootState) => state.root.authToken);

  const getItemQuery = async () => {
    if(user && itemID){
      return client?.service('items').get(itemID, {
        headers: { Authorization: authToken },
        user: user
      })
    } else {
      return;
    }
  }
  const getCategoriesQuery = async () => {
    if(user){
      return client?.service('categories').find({
        headers: { Authorization: authToken },
        user: user,
        $limit: 50
      })
      .then((res: any) => res.data)
      .then((data: ICategory[]) => {
        return data.reduce((acc, elt) => ({
          ...acc, 
          [elt._id]: elt.name,
        }), {});
      })
    } else {
      return { data: null };
    }
  }
  const { data: item, isLoading } = useQuery(['item', itemID], getItemQuery);
  const { data: categories } = useQuery('categories', getCategoriesQuery);

  console.log('item: ', item)


  return (
    <>
      {!isLoading &&
        <ItemDetail item={item} categories={categories}/>
      }
    </>
  );
}