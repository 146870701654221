import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { IItem } from '../../Interfaces/Database/Item';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useLocation } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, CircularProgress, Grid, IconButton, TextField } from '@mui/material';
import { IAuction } from '../../Interfaces/Database/Auction';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import client from '../../client';
import { QueryResult } from '../../Interfaces/Service';

interface IProps {
  auction: IAuction | null
}

export default function ItemsTable({ auction }: IProps) {
  let location = useLocation();
  const user = useAppSelector((state: RootState) => state.root.user);
  const authToken = useAppSelector((state: RootState) => state.root.authToken);
  
  const [search, setSearch] = React.useState<number | string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [itemPage, setItemPage] = React.useState<IItem[]>([]);
  const [totalItems, setTotalItems] = React.useState<number>(0);

  const canEdit = React.useMemo(() => auction ? auction.state < 3 : false, [auction])

  React.useEffect(() => {
    setLoading(true);
    if(!user || !auction) return;
    client?.service('items').find({
      Authorization: authToken,
      user: user || undefined,
      query: {
        auctionID: auction?._id,
        $limit: 0
      }
    })
      .then((res: any) => {
        setTotalItems((res as QueryResult<IItem>).total);
        setSearch('');
        getNextPage(page);
      })
      .catch(() => setLoading(false));
  }, [ user, auction ]);

  const getNextPage = (newPage: number) => {
    setLoading(true);
    client?.service('items').find({
      Authorization: authToken,
      user: user || undefined,
      query: {
        auctionID: auction?._id,
        $select: ['itemNumber', 'category', 'title', 'isSold', 'purchaseBidder', 'purchasePrice'],
        $sort: { itemNumber: 1 },
        $limit: rowsPerPage,
        $skip: newPage * rowsPerPage
      }
    })
    .then((res: any) => res.data)
    .then((data: IItem[]) => {
      setItemPage(data);
    })
    .finally(() => setLoading(false));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    getNextPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getNextPage(0);
  };

  const handleSearch = () => {
    setLoading(true);
    client?.service('items').find({
      Authorization: authToken,
      user: user || undefined,
      query: {
        auctionID: auction?._id,
        $select: ['itemNumber', 'category', 'title', 'isSold', 'purchaseBidder', 'purchasePrice'],
        itemNumber: search,
        $limit: 1,
      }
    })
    .then((res: any) => res.data)
    .then((data: IItem[]) => {
      setItemPage(data);
    })
    .finally(() => setLoading(false));
  }

  const clearSearch = () => {
    setSearch('');
    getNextPage(page);
  };

  return (
    <Paper sx={{ width: '100%' }}>
       <Grid container direction={'row'} style={{ padding: '10px', alignItems: 'center' }}>
        <TextField
          value={search}
          type='number'
          style={{ display: 'block', margin: '10px' }}
          label={'Item Number'}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"  
          onChange={(e: any) => setSearch(e.target.value)}
        />
        <Button
          style={{ height: '55px', margin: '10px' }}
          variant="contained"
          onClick={handleSearch}
        >Search</Button>
        <Button
          style={{ height: '55px', margin: '10px', backgroundColor: 'gray' }}
          variant="contained"
          onClick={clearSearch}
        >Clear</Button>
       </Grid>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
                <TableCell key={'itemNumber'}>Item Number</TableCell>
                <TableCell key={'category'} style={{ minWidth: 100 }}>Category</TableCell>
                <TableCell key={'title'} style={{ minWidth: 170 }}>Title</TableCell>
                <TableCell key={'sold'} style={{ minWidth: 170 }}>Sold (bidder, price)</TableCell>
                <TableCell key={'icon'}>
                  {canEdit &&
                    <IconButton>
                      <Link to={`${location.pathname}/create`} style={{ textDecoration: 'none', color: 'black' }}>
                        <AddCircleIcon color='primary'/>
                      </Link>
                    </IconButton>
                  }
                </TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
              {loading
                ? <TableRow>
                    <TableCell/><TableCell/>
                    <TableCell align='center' style={{display: 'flex', justifyContent: 'center'}}>
                      <CircularProgress style={{ display: 'inline-block', position: 'relative', margin: '0 auto' }}/>
                    </TableCell>
                    <TableCell/><TableCell/>
                  </TableRow>
                : <>
                    {itemPage.length > 0 
                      ? itemPage.map((item: IItem) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={item._id}>
                              <TableCell key={`itemNumber-${item._id}`}>{item.itemNumber}</TableCell>
                              <TableCell key={`category-${item._id}`}>{item.category}</TableCell>
                              <TableCell key={`title-${item._id}`}>{item.title}</TableCell>
                              <TableCell key={`sold-${item._id}`}>{item.isSold && '#' + item.purchaseBidder + ', $' + item.purchasePrice}</TableCell>
                              <TableCell key={`icon-${item._id}`}>
                                {canEdit &&
                                  <Link to={`${location.pathname}/${item._id}`} style={{ textDecoration: 'none', color: 'black' }}>
                                    <EditIcon/>
                                  </Link>
                                }
                              </TableCell>
                            </TableRow>
                          );
                        })
                      :
                        <TableRow>
                          <TableCell/><TableCell/>
                          <TableCell align='center' style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>No Items Found</TableCell>
                          <TableCell/><TableCell/>
                        </TableRow>
                    }
                </>
              }
            </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}